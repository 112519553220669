"use strict";

import compose from 'lodash.compose';

import {
    parseQueryString,
    maybeDefaultTabs
} from '../queryutils'

import {TAB_FACET, DEFAULT_LIST_TYPE, COMPUTED} from '../config';

const pageTitle = query => options => {
    if (typeof options.pageTitle != 'undefined' && query.indexOf('!padrenull') == -1) {
        delete options.pageTitle;
    }
    else if(query.toLowerCase() == '!padrenull') {

        if(typeof options.pageTitle == 'undefined' || options.pageTitle.match(COMPUTED)) {

            let pageTitle = [COMPUTED];
            if(typeof options['f.Educational+level%7CB'] != 'undefined') {
                pageTitle.push(options['f.Educational+level%7CB']);
            }
            if(typeof options['f.Subject%7Cs'] != 'undefined') {
                pageTitle.push(options['f.Subject%7Cs']);
            }
            if(pageTitle.length === 1) {
                options.pageTitle = `${COMPUTED}${options['f.Tabs%7Ctype']} Results`;
            }
            else {
                options.pageTitle = pageTitle.join(' ');
            }
        }


    }

    return options;
}

const confirmObject = options =>
    typeof options != 'object' ? parseQueryString(options) : options;

const formatType = options => {
    if (typeof options['f.Tabs|type'] != 'undefined') {
        options[TAB_FACET] = options['f.Tabs|type'];
        delete options['f.Tabs|type'];
    }
    return options;
}

const setMedia = mediaQuery => options => {
    if (typeof options.device == 'undefined') {
        options.device = mediaQuery();
    }
    return options;
}

const setListType = options => {
    if(options[TAB_FACET] == 'products' && typeof options['listType'] == 'undefined') {
        options['listType'] = DEFAULT_LIST_TYPE ;
    }
    return options;
}


const optionsFactory = (query, mediaQuery) =>
    compose(
        setMedia(mediaQuery),
        pageTitle(query),
        formatType,
        maybeDefaultTabs,
        confirmObject,
        setListType
    )

export default optionsFactory;