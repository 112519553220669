/**
 *
 *
 *
 *
 * Query String Utils
 */

import {capitalizeAll, isDate} from './utils'

import {COLLECTION, DEFAULT_TAB_FACET, TAB_FACET} from  './config';

/**
 * Replace str for facet query string part
 * @type {RegExp}
 */
const REPLACE_STR = /\+|%2F|%7.*$/g

const query = {};

/**
 * Encode query params
 * @param val
 * @returns {string}
 */
const encode = (val) => {
    return encodeURIComponent(val)
        .replace(/%2B/gi, '+')
        .replace(/%25/gi, '%')
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%5B/gi, '[')
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']');

}



/**
 * Get query param value or return empty string
 * @param qString
 */
const qGet = qString => param =>
    typeof qString[param] != 'undefined' ? qString[param] : "";

/**
 * Create a window.location.search value from an object
 * @param qString
 */
const joinQuery = qString =>
    "?" + Object.keys(qString).sort().map(key => encode(key)+"="+encode(qString[key])).join("&");

/**
 * Create a full request_uri with query string from the path and an object of params
 * @param qString
 */
const pathPlusQuery = getWindow => qString =>
    getWindow().location.pathname + joinQuery(qString);

/***
 * Create the current request_uri with query string
 */
const pathPlusQueryLocCls = getWindow => () =>
    getWindow().location.pathname + getWindow().location.search;

/**
 * Creates a function which adds to pathname
 * @param getWindow
 */
const addPathCls = getWindow => qstring =>
    getWindow().location.pathname + qstring;

/**
 * Factory
 * @deprecated
 * @param getWindow
 */
const queryGetWindowDepFactory = getWindow =>
    [
        pathPlusQueryLocCls(getWindow),
        addPathCls(getWindow)
    ]

/**
 * Get the first part of an array
 * @param bits
 */
const getFirst = bits =>
    bits[0]

/**
 * Get the key from a querystring part
 * @param qStringPart
 */
const getKey = qStringPart =>
    getFirst(qStringPart.split("="))

const DIRTY = /&|<|>|"|script|iframe|=|\(|\)|\[|\]/gi;

const clean = str =>
    str.replace(/'/gi, 'APOSTROPHE')
        .replace(DIRTY, ' ')
        .replace(/APOSTROPHE/gi, '&#39;');

/**
 * Replace characters for visual display
 * @param query
 */
const queryForDisplay = query =>
    query.replace(/\+/gi, ' ').replace(/%2F/gi, '/');

/**
 * Parse the window.location.search into an object
 * @param search
 * @returns {{}}
 */
function parseQueryString(search) {
    let subSearch = search.substr(0, 1) == '?' ? search.substr(1).split('&') : search.split('&');
    subSearch.sort();
    let qString = {};
    subSearch.forEach(function addPart(part) {
        var splitPart = part.split("=");
        if(splitPart[0] == 'query' || splitPart[0] == 'pageTitle' ) {
            qString[splitPart[0]] = splitPart[1];
        }
        else {
            qString[splitPart[0]] = splitPart[1];
        }

    });
    return qString;
}

/**
 * A params serializer for Axios when using axios.request
 * @param params
 * @returns {string|*}
 */
const paramsSerializer = params => {
    let serializedParams;
    var parts = [];
    params.forEach(function serialize(val, key) {
        if (val === null || typeof val === 'undefined') {
            return;
        }

        if (Array.isArray(val)) {
            key = key + '[]';
        }

        if (!Array.isArray(val)) {
            val = [val];
        }

        val.forEach(function parseValue(v) {
            if (isDate(v)) {
                v = v.toISOString();
            } else if (isObject(v)) {
                v = JSON.stringify(v);
            }
            parts.push(encode(key) + '=' + encode(v));
        });
    });
    serializedParams = parts.join('&');
    return serializedParams;
}

/**
 * Generate a query string from the query and other parameters
 * @param query
 * @param options
 * @returns {string}
 */
const queryFactory = (query, options = {}) => {
    let qs = {};
    for(let k in options) qs[k] = options[k];
    qs.collection = COLLECTION;
    qs.query = query;
    return "/s/search.json" + joinQuery(qs);
};

/**
 * Splits the query into words
 * @param query
 */
const qparts = query =>
    query.split("+")

/**
 * Split the query string part and get the query key
 * @param qstring
 */
const qKey = qstring =>
    qstring.toString().split("=")[0];

/**
 * Get the value of a query string part
 * @param qstring
 */
const qVal = qstring =>
    qstring.toString().split("=")[1];

/**
 * Replace facet query string parts into spaces
 * @param qstring
 */
const replaceJoins = qstring => {
    if(typeof qstring == 'undefined') {
        qstring = "";
    }
    return  qstring.replace(REPLACE_STR, " ");
}

/**
 * Remove facet 'f.'
 * @param qstring
 */
const removeF = qstring =>
    qstring.substr(2)

/**
 * Convert facet query string to title
 * @param qstring
 */
const title = qstring =>
    capitalizeAll(replaceJoins(removeF(qKey(qstring)))).trim();

/**
 * Get title from a query value part
 * @param qstring
 * @returns {*|string|String}
 */
const titleFromQVal = qstring => {
    let result = capitalizeAll(replaceJoins(qVal(qstring))).trim();
    return result;
}

/**
 * Format title from query
 * @param query
 */
const titleFromQuery = query =>
    capitalizeAll(replaceJoins(query)).trim()

/**
 * Add facet to query string
 * @param addPath
 * @param addFacetString
 */
const qStringFacetAddCls = (addPath, addFacetString) => qPart =>
    addPath(addFacetString(qPart));

/**
 * Remove facet from query string
 * @param addPath
 * @param removeFacetString
 */
const qStringFacetRemoveCls = (addPath, removeFacetString) => qPart =>
    addPath(removeFacetString(qPart));

/**
 * Get query only
 * @param options
 */
const queryOnly = options =>
    joinQuery({collection: COLLECTION, query: options['query']});

/**
 * Get breadcrumb facets
 * @param options
 */
const breadcrumbFacets = options =>
    Object.keys(options).map(key => {
        if(key.substr(0,2) == 'f.') {
            return encode(key)+"="+encode(options[key]);
        }
        else {
            return false;
        }
    }).filter(item => item);

/**
 * Add Default Tabs
 * @param options
 * @returns {*}
 */
const addDefaultTabs = options => {
    options[TAB_FACET] = DEFAULT_TAB_FACET;
    return options;
}

/**
 * Maybe add Default tabs
 * @param options
 */
const maybeDefaultTabs = options =>
    typeof options[TAB_FACET] == 'undefined' ?
        addDefaultTabs(options) : options;

/**
 * Set current query string options in the store
 * @param qstring
 */
const currentOptionsCls = store => qstring => {
    let current_options = store('current_options');
    let qstringParts = qstring.split("=")
    current_options[qstringParts[0]] = qstringParts[1]
    store('current_options', current_options);
    return current_options;
}

/**
 * Remove param from the options in the store
 * @param param
 */
const currentOptionsRemoveCls = store => param => {
    let current_options = store('current_options');
    delete current_options[param];
    store('current_options', current_options);
    return current_options;
}

/**
 * Add a facet query string part to the store
 * @param qstring
 */
const addFacetStringCls = currentOptions => qstring =>
    joinQuery(currentOptions(qstring));

/**
 * Remove a facet query string part from the store
 * @param qstring
 */
const removeFacetStringCls = currentOptionsRemove => qstring =>
    joinQuery(currentOptionsRemove(getKey(qstring)));

/**
 * The rest asset funnelback combo munges the query string.
 * This fixes the munge.
 * @param facets
 */
const normalizeFacets = facets =>
    Array.isArray(facets) ? facets.map(qstringPart => qstringPart.replace(/%20/gi, '+').replace(/\|/gi, '%7C')) : facets.replace(/%20/gi, '+').replace(/\|/gi, '%7C')



export {
    encode,
    qGet,
    joinQuery,
    queryGetWindowDepFactory,
    getFirst,
    getKey,
    parseQueryString,
    paramsSerializer,
    queryFactory,
    qparts,
    qKey,
    replaceJoins,
    removeF,
    title,
    qStringFacetAddCls,
    qStringFacetRemoveCls,
    queryOnly,
    titleFromQuery,
    titleFromQVal,
    breadcrumbFacets,
    maybeDefaultTabs,
    currentOptionsCls,
    currentOptionsRemoveCls,
    addFacetStringCls,
    removeFacetStringCls,
    addPathCls,
    pathPlusQueryLocCls,
    normalizeFacets,
    queryForDisplay,
    DIRTY,
    clean
}
