import events from './events';

const jquery = {};

/**
 * Set up cart buttons for products to use Add to Cart animation
 * @param document
 * @param jQuery
 * @param facetValue
 */
jquery.setUpCartButtons = (document, sq, jQuery, facetValue) => {
    if(facetValue == 'products') {
        jQuery('#result-set-products').remove();
        events.ready(document, `result-set-${facetValue}`, (id) => {
            sq.bindAddToCart('.add-to-cart')
        })
    }
}

jquery.setUpBestbets = (document, sq, jQuery) => {
    events.ready(document, 'best-bets-content', (id) => {
        setTimeout(() => {sq.makeCarousels();}, 800)

    })
}


export default jquery;
