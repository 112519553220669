import {html} from '../viewutils';

/**
 * Remove options for active facets
 * @param facets
 */
const remove = facets => facets.length ? html`
        <div class="filters__group-h selected">
            <p class="filters__heading-selected first-child">SELECTED</p>
            <a href="javascript:void(0);" onclick="return ev(this, 'removeAll')" class="filters__more-button">CLEAR</a>
        </div>
        <div class="filters__container">
            <div class="filters__group-h selected">
                ${facets}
            </div>
        </div>
    ` : '';

/**
 * Facets available for refining search
 * @param facets
 */
const select = facets => facets.length ? html`
        <p class="filters__heading" id="refine__by">Refine by</p>
        ${facets}
    ` : '';


/**
 * View: Sidebar view
 * @param  model funnelback/models/sidebar.js sidebar()
 * @returns {*}
 */
const sidebar = model => {
    return html`
        <div id="facet-panel" class="panel ${model.mobileDesktopClasses}">
            <div class="padder">
                <div class="filters__content${model.expandFilters ? ' show-hidden-facets' : ''}" id="filters__content">
                    ${remove(model.facets.remove.map(model.view.remove))}
                    ${select(model.facets.select.map(model.view.add))}
                    <a href="javascript:void(0)" class="button more--facets mrgBtm0" id="sidebarMoreLess" onclick="return ev(this, 'expandFilters')">
                        <span class="label">${model.moreLessFilters} Filters</span>
                    </a>
                    <!-- facet-1 -->
                </div><!-- /filters__content -->
            </div><!-- /padder -->
        </div>    
    `;
};

export {sidebar};