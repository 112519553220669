import {
    joinQuery,
    getKey,
    clean
} from '../queryutils'

import {
    html
} from '../viewutils'

/**
 * View: breadcumb
 * @param breadcrumb
 * @returns {string}
 */
const breadcrumb = (title, url) => {
    return `
    <li class="breadcrumbs__item">
        <a onclick="return ev({link: this, event: event}, 'reroute')" data-request="/search/${url}"
        href="/search/${url}" class="breadcrumbs__link">${clean(decodeURIComponent(title))}</a>
        <span class="breadcrumbs__divider" aria-hidden="true">&gt;<span>
    </li>`;

}

const isNotReady = url =>
    url.substr(0, 1) != '?';

/**
 * Create list of breadcrumbs using breadcrumb with correct urls
 * @param accum
 * @param breadcrumbObj
 * @param index
 * @param arr
 * @returns {*}
 */
const breadcrumbReduce = (accum, breadcrumbObj, index, arr) => {

    if (typeof accum == 'string') return accum;
    if (arr.length == 1) return "";
    let url = breadcrumbObj.qstring;
    if (isNotReady(url)) {
        let final = {};
        // let selector = index == 0 ? index : index + 1;
        let selector = index + 1;
        let key = getKey(arr[selector].qstring).replace(/f.Educational%20level%7CB/gi, 'f.Educational+level%7CB');
        for (let k in accum.options) {
            if (k != key) {
                final[k] = accum.options[k];
            }
        }
        url = joinQuery(final);
    }

    accum.result = accum.result + breadcrumb(breadcrumbObj.title, url);
    // If this is the final array item return the string result else return the object
    let r = (arr.length - 2) == index ? accum.result : accum;
    return r;
}

/**
 * View for last breadcrumb ( the current page )
 * @param fb
 */
const finalBreadcrumb = fb =>
    `<li class="breadcrumbs__item breadcrumbs__current">${clean((fb.title))}</li>`;

/**
 * View: breadcrumbs
 * @param breadcrumbs
 * @returns {*}
 */
const breadcrumbs = model => html`
        <nav class="breadcrumbs ${model.showBreadcrumbs}">
            <p id="breadcrumb__label" class="visuallyhidden">You are here:</p>
            <ol class="breadcrumbs__list" aria-labelledby="breadcrumb__label">
                <li class="breadcrumbs__item">
                    <a href="/" class="breadcrumbs__link">Home</a>
                    <span class="breadcrumbs__divider" aria-hidden="true">&gt;<span>
                </li>
                ${model.breadcrumbs.reduce(breadcrumbReduce, {result: "", options: model.options})}
                ${finalBreadcrumb(model.breadcrumbs.pop())}
            </ol>
        </nav>
    `;

export {breadcrumbs}
