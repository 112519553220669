import {FOR_SEARCH_TOOLS, TAB_FACET, SORT_OPTIONS} from '../config'

import {toolbarFacets, getSortOptions} from '../viewutils';

import {qGet} from '../queryutils';

import {content as view, fallback as fallbackView} from '../views/content';

/**
 * Should filters be open or closed on mobile
 * @param value
 */
const toggleFilters = value =>
    value == 'open' ? 'filters=close' : 'filters=open';

/**
 * Get filters state for mobile.
 * Display or hide.
 * @param value
 */
const filters = value =>
    typeof value == 'undefined' ? 'filters=open' : toggleFilters(value);

/**
 * Get class for listing.  List or Grid.
 * @param options
 */
const listTypeCssClass = options =>
    qGet(options)('listType') == 'grid' ? "col-xs-6 col-md-4 col-lg-3"  : "col-xs-6 col-md-12 col-lg-12";

/**
 *
 * @param resultTmpls
 * @param query
 * @param location
 * @param options
 * @returns {views.content.grid|views.content.list|views.content.events}
 */
const tabViewSelector = (query, location, options) => {
    let key = options[TAB_FACET].toLowerCase().replace(/\+/gi, "");
    let result;
    switch(key) {
        case 'products':
            result = view.result.product(query, listTypeCssClass(options))
            break;
        case 'events':
            result = view.result.events(query, location)
            break;
        case 'sitecontent':
            result = view.result.contents(query, location)
            break;
        default:
            result = view.result.contents(query, location)
    };
    return result;
}

/**
 *
 * @param response
 * @param results
 * @param options
 * @param resultTmpl
 * @param facets raw facet data from response
 * @param categoryCls facet.toolbarcat.mobile|facet.toolbarcat.default
 * @returns {{options: *, facets, resultPacket: *, resultsSummary: *, exhibits: *, results: *, tabFacet: string, sortOptions, filters, view: {result: *, currentSort, facet: *, pagination: pagination}}}
 */
export const content = (response, results, options, facets, query, location, categoryCls, optionBasic, pagination, fallback=false) => {
    return {
        options,
        facets: toolbarFacets(facets, FOR_SEARCH_TOOLS),
        resultPacket: response.resultPacket,
        resultsSummary: response.resultPacket.resultsSummary,
        exhibits: response.curator.exhibits,
        results,
        tabFacet: options[TAB_FACET].replace(/%20/gi, "").replace(/\+/gi, "").toLowerCase(),
        sortOptions: getSortOptions(SORT_OPTIONS, options),
        filters: filters(options['filters']),
        view: {
            result: tabViewSelector(query, location, options),
            currentSort: optionBasic(options.sort),
            facet: fallback ? categoryCls(options, fallback.qStringFacetRemoveAdd): categoryCls(options),
            pagination,
            toolbar: fallback ? fallbackView.toolbar(location, fallback.qStringFacetAdd) : view.toolbar
        }
    }
}
