"use strict";

import compose from 'lodash.compose';

const changeAuthorName = item => {
    if(item.categories.length) {
        item.categories[0].values = item.categories[0].values.map(value => {
            value.data = value.data.replace(/,/gi, ', ');
            value.label = value.label.replace(/,/gi, ', ');
            return value;
        });
    }
    return item;
}

const author = facets =>
    facets.map(function maybeChangeAuthorName(item) {
        return item.name == 'Author' ? changeAuthorName(item) : item;
    })

const preprocess = compose(author);

const beforeFacetProcessing = facets =>
    preprocess(facets);

export {
    beforeFacetProcessing
}