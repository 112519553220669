"use strict"
import axios from 'axios';
// Included for IE
import 'babel-polyfill';

import initStore from './funnelback/store'

import jquery from './funnelback/jquery';

import {
    ready,
    getWindowCls,
    capitalizeAll,
    check
} from './funnelback/utils'


import {
    queryFactory,
    parseQueryString,
    qGet,
    currentOptionsCls,
    currentOptionsRemoveCls,
    addFacetStringCls,
    removeFacetStringCls,
    addPathCls,
    pathPlusQueryLocCls,
    qStringFacetAddCls,
    qStringFacetRemoveCls,
    maybeDefaultTabs,
    queryForDisplay,
    clean
} from './funnelback/queryutils'

import {
    html,
    mediaQueryFactory,
} from './funnelback/viewutils'

import { searchForm } from './funnelback/views/search';

import { content as contentModel } from './funnelback/models/content';

import { content as contentView } from './funnelback/views/content';

import { facet as facetView } from './funnelback/views/facet';

import { sidebar as sidebarView } from './funnelback/views/sidebar';

import { sidebar as sidebarModel } from './funnelback/models/sidebar';

import { breadcrumbs as breadcrumbsView } from './funnelback/views/breadcrumbs';

import { breadcrumbs as breadcrumbsModel } from './funnelback/models/breadcrumbs';

import { noresults } from './funnelback/models/noresults';

import events from './funnelback/events';

import header from './funnelback/views/header';

import optionsFactory from './funnelback/custom/options';

import { TAB_FACET, DEFAULT_LIST_TYPE, COMPUTED } from './funnelback/config'

/**
 *
 *
 *
 *
 * Constants TODO: Make into one object
 */

/***
 * Base URI
 * @type {string}
 */
const BASE = "";

const ELS = {};

/**
 * sidebar container element
 * @type {Element}
 */
ELS.sidebar = document.getElementById('sidebar');

/**
 * main content container element
 * @type {Element}
 */
ELS.content = document.getElementById('content-v2');

/***
 * Breadcrumbs container element
 * @type {Element}
 */
ELS.breadcrumbs = document.getElementById('breadcrumbs');

ELS.header_main = document.getElementById('header__main');

ELS.header_mobile = document.getElementById('header__main-mobile');

const getWindow = getWindowCls(window);

const TITLE_TAG = document.getElementsByTagName('title')[0];


const mediaQuery = mediaQueryFactory(window, document);


var QUERY_DOMAIN = "";

if (getWindow().location.origin == 'http://oup-new.clients.squiz.net') {
    // QUERY_DOMAIN = "http://oup-funnelback.clients.squiz.net";
}

const [isOption, store] = initStore(getWindow);

const nQueryLoc = (options) => {
    let final = {query: store('current_query')};
    let remove = ['listType', 'filters'];
    for (var key in options) {

        if (remove.indexOf(key) == -1) {
            final[key] = options[key];
        }
    }
    let keys = Object.keys(final);
    keys.sort();
    let result = keys.reduce((accum, value) => {
        accum = accum + value + options[value];
        return accum;
    }, "");
    return result;
}


const storeResults = (data) =>
    store(
        'results_set',
        data.response.resultPacket.results
    )

const concatResults = (data) =>
    store(
        'results_set',
        store('results_set').concat(data.response.resultPacket.results)
    )


/**
 * Get the result list from Funnelback json
 * @param data
 * @returns {*}
 */
const getResultsCls = (store) => (data) => {
    // Logic for Pagination
    let current_query = store('current_query');
    let previous_query = store('previous_query');
    if (current_query != previous_query) {
        return storeResults(data);
    }
    let current_options = store('current_options');
    if (typeof current_options['start_rank'] == 'undefined') {
        return storeResults(data);
    }
    let previous_options = store('previous_options');
    var previous_start_rank = 0;
    if (typeof previous_options['start_rank'] != 'undefined') {
        previous_start_rank = parseInt(previous_options['start_rank'])
    }
    let current_start_rank = parseInt(current_options['start_rank']);
    if (current_start_rank > previous_start_rank) {

        return concatResults(data)
    }
    else if (previous_start_rank == current_start_rank) {

        return concatResults({response: {resultPacket: {results: []}}})
    }
    else {

        return storeResults(data)
    }
}


const getResults = getResultsCls(store);

/**
 *
 *
 *
 *
 * View Renderers
 */

/**
 * Display the search form to the user
 * @param el
 */
const renderSearchForm = (el) => {
    document.getElementById(el).innerHTML = searchForm();
};

const maybeBestbets = response => {
    if (response.curator.exhibits) {
        jquery.setUpBestbets(getWindow().document, getWindow().sq, getWindow().jQuery);
    }
}

/**
 *
 * Render contents in browser
 * @param resultPacket
 * @param options
 * @param contentType
 */
const listContentsRender = (response, results, options, facets) => {
    jquery.setUpCartButtons(getWindow().document, getWindow().sq, getWindow().jQuery, options[TAB_FACET]);
    maybeBestbets(response);
    ELS.content.className = 'col-xs-12 col-md-8 col-lg-9';
    ELS.content.innerHTML = contentView.main(
        contentModel(
            response,
            results,
            options,
            facets,
            store('current_query'),
            getWindow().location,
            options['device'] == 'is-mobile-only' ? facetView.toolbarcat.mobile : facetView.toolbarcat.default,
            contentView.optionBasic,
            contentView.pagination
        )
    );

};

const noResultsContentsRender = (currentQuery, response, extraFacets, options) => {
    maybeBestbets(response);
    ELS.content.className = 'col-xs-12 col-md-8 col-lg-9';
    ELS.content.innerHTML = contentView.noResultsContainer(
        noresults(currentQuery, response, extraFacets, options, facetView.toolbarcat.default)
    );
}


const moreLessFilters = () =>
    store('expand_filters') ? 'Less' : 'More';

/**
 * Render sidebar in the browser
 * @param facets
 */
const sidebarRender = model => {
    ELS.sidebar.innerHTML = sidebarView(model);
    let document = getWindow().document;
    events.ready(
        document,
        'sidebarMoreLess',
        events.sidebar.moreLess(document, model.facets, store('facet_expand'), moreLessFilters())
    );
};

/**
 * Render breadcrumbs in browser
 * @param facets
 */
const breadcrumbsRender = (options, facets) => {
    ELS.breadcrumbs.innerHTML = breadcrumbsView(breadcrumbsModel(facets, options));
}
/**
 *
 *
 *
 * Funnelback Client Response
 */



const mobileFilters = (options, results) => {
    if (check(options, 'device', 'is-mobile-only') && check(options, 'filters', 'open') && results) {
        ELS.header_main.className = ELS.header_main.className + " mobile-hide";
        ELS.header_mobile.style.display = 'block';
        ELS.header_mobile.innerHTML = header.mobile();
    }
    else {
        ELS.header_main.className = ELS.header_main.className.replace(/ mobile-hide/gi, '');
        ELS.header_mobile.innerHTML = '';
        ELS.header_mobile.style.display = 'none';
    }
}

/**
 * Axios promise response handler
 * @param options
 */
const debugResponse = (options) => (data) => {

    getWindow().resultData = data;
    if (typeof data.data != 'undefined' && typeof data.data.response != 'undefined') {
        data = data.data;
    }
    store(nQueryLoc(options), data);
    let results = getResults(data);
    let currentQuery = store('current_query');

    // Choose an initialise template result, resultEvents or resultSiteContents

    // let extraFacets =  data.extraSearches['tabs'].response.facets;
    let extraFacets = data.extraSearches['FACETED_NAVIGATION'].response.facets;
    if (results.length) {

        listContentsRender(
            data.response,
            results,
            options,
            extraFacets
        );

        sidebarRender(
            sidebarModel(data, options, store('expand_filters'), facetView)
        );

        breadcrumbsRender(options, data.question.selectedCategoryValues);

        mobileFilters(options, true);

    }
    else {

        noResultsContentsRender(
            currentQuery,
            data.response,
            extraFacets,
            options
        );
        ELS.breadcrumbs.innerHTML = "";

        sidebarRender(
            sidebarModel(data, options, store('expand_filters'), facetView)
        );

        mobileFilters(options, false);
    }

}

const errorResponse = (error) => {
    console.log('An error occured');
    console.log(error);
}

/**
 * Query funnelback
 * @param query
 * @param options
 * @param callback
 * @param error
 */
const basicQuery = (query, options = {}, callback = debugResponse, error = errorResponse) => {
    let queryObj = queryFactory(query.replace(/ /g, "+"), options);

    axios.get(QUERY_DOMAIN + queryObj, {
        headers: {
            'Cache-Control': 'max-age=3600'
        }
    })
        .then(callback(options))
    // .catch(error);
}

/**
 *
 *
 *
 * Request Handlers
 */

/**
 * Route handler for search
 * @param query
 * @param options
 */
function searchHandler(query, options = {}) {

    options = optionsFactory(query, mediaQuery)(options);

    store('current_query', query)
    store('current_options', options)
    TITLE_TAG.textContent = clean(capitalizeAll(typeof options['pageTitle'] != 'undefined' ? options['pageTitle'] : query).replace(new RegExp(COMPUTED, 'gi'), '') + ' - OUP');
    if (query.toLowerCase() != '!padrenull') {
        ready(getWindow().document, "site-search_input", function setSearchInput(id) {
            getWindow().document.getElementById(id).value = queryForDisplay(query).replace(/%27/gi, "'");
            ;
        });
    }
    let data = store(nQueryLoc(options))
    if (data) {
        debugResponse(options)(data)
    }
    else {
        if (ELS.sidebar && typeof options['start_rank'] == 'undefined') {
            ELS.sidebar.innerHTML = "";
        }
        if (!ELS.content) {
            ready(document, 'content-v2', () => {
                ELS.content.innerHTML = contentView.loading();
                ELS.content.className = 'col-xs-12 col-md-12 col-lg-12';
            });
        }
        else if (ELS.content && typeof options['start_rank'] == 'undefined') {
            ELS.content.innerHTML = contentView.loading();
            ELS.content.className = 'col-xs-12 col-md-12 col-lg-12';
        }

        basicQuery(query, options)
    }


}

/**
 * Route handler for home
 */
function homeHandler() {
    let loc = getWindow().location;
    if (loc.search) {
        let qString = parseQueryString(loc.search);

        let query = qGet(qString)('query').replace(/%20/gi, "+");
        if (!query) {
            qString.query = query = '!padrenull';
            qString.pageTitle = COMPUTED + 'All Results - Please enter a query'
        }

        searchHandler(query, qString);

    }

}

/**
 *
 *
 *
 *
 * Routes
 */

/**
 * Set up the Router
 * @returns {*}
 */
const routerFactory = () => {

    //Instanciate the router
    var router = new Router();

    // Categories route
    router.route(BASE + '/search/:query/', searchHandler);

    // Home route
    router.route(BASE + '/search/', homeHandler);

    router.route(BASE + '/search', homeHandler);

    return router;
}

const amodelFactory = (getWindow, store, mediaQuery, QUERY_DOMAIN, ELS) => {
    let currentOptions = currentOptionsCls(store);
    let currentOptionsRemove = currentOptionsRemoveCls(store);
    let addFacetString = addFacetStringCls(currentOptions);
    let removeFacetString = removeFacetStringCls(currentOptionsRemove);
    let addPath = addPathCls(getWindow);
    return {
        currentOptions,
        currentOptionsRemove,
        addFacetString,
        removeFacetString,
        qStringFacetAdd: qStringFacetAddCls(addPath, addFacetString),
        qStringFacetRemove: qStringFacetRemoveCls(addPath, removeFacetString),
        pathPlusQueryLoc: pathPlusQueryLocCls(getWindow),
        addPath,
        store,
        getWindow,
        mediaQuery,
        window: getWindow(),
        document: getWindow().document,
        QUERY_DOMAIN,
        ELS
        // keydownListener: events.keydownListener(getWindow().document)
    }
}

/**
 *
 *
 *
 *
 * Bootstrap
 */

const browserBootstrap = () => {


    let router = routerFactory();
    // Start the router
    router.start();

    /**
     * Attach event handler manager to the dom
     */
    let ev = getWindow().ev = events.factory(
        router,
        events.registry,
        amodelFactory(
            getWindow,
            store,
            mediaQuery,
            QUERY_DOMAIN,
            ELS
        )
    )

    let loc = getWindow().location;

    store('device', mediaQuery());

    /**
     * Redirect if the page is /search-poc.html to /search/
     */

    if ('/search/' == loc.pathname || '/search' == loc.pathname) {
        ev('/search/' + loc.search.replace(/%20/gi, '+').replace(/\|/gi, '%7C'), 'confirmContainers');
    }
    else if ('/store' == loc.pathname) {
        ev(`/search/?${TAB_FACET}=products&pageTitle=All%20Results&query=!padrenull` + loc.search.replace(/%20/gi, '+').replace(/\|/gi, '%7C'), 'confirmContainers');

    }

    /**
     * Render the search form
     */
    renderSearchForm('search-form');
    document.body.className = document.body.className.replace("no-js", "");

}

browserBootstrap();
