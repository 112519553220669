import partition from 'lodash.partition'
import {Option, Breadcrumb} from './types';
import {namedMap, swap, capitalizeAll} from './utils'
import {title, titleFromQVal, joinQuery, getKey, queryOnly, titleFromQuery, qGet, qparts, encode} from './queryutils'
import {TAB_FACET, DEFAULT_LIST_TYPE, NORMALIZE_ORDER, NORMALIZE_FUNC} from './config'


/**
 *
 *
 *
 *
 * View Utils
 */


/**
 * OUP metaMap
 * @type {{3: string, a: string, b: string, B: string, c: string, d: string, E: string, H: string, I: string, M: string, O: string, s: string, t: string}}
 */
const METAMAP_PROTO = {
    "1": "assetid",
    "3": "price_au",
    "4": "price_nz",
    "a": "author",
    "b": "isbn",
    "B": "education_level",
    "c": "content",
    "d": "date_published",
    "E": "format",
    "H": "author",
    "I": "image",
    "M": "year_published",
    "O": "series",
    "s": "subject",
    "t": "title",
    "L": "edition",
    "cartflag": "cart_flag",
    prodSalesStatus: "product_sales_status",
    year: "year",
    day: "day",
    month: "month",
    monthW: "monthW",
    state: "state",
    cost: "cost"
};

/**
 * OUP
 * Friendly metamap keywords
 * Used as a code completion helper
 * @type {{price_au: string, author: string, isbn: string, education_level: string, content: string, date_published: string, format: string, author: string, image: string, year_published: string, series: string, subject: string, title: string}}
 */
const METAMAP = {
    price_au: "price_au",
    price_nz: "price_nz",
    author: "author",
    isbn: "isbn",
    education_level: "education_level",
    content: "content",
    date_published: "date_published",
    format: "format",
    author: "author",
    image: "image",
    year_published: "year_published",
    edition: "edition",
    series: "series",
    subject: "subject",
    title: "title",
    assetid: "assetid",
    year: "year",
    day: "day",
    month: "month",
    monthW: "monthW",
    state: "state",
    cost: "cost",
    cart_flag: "cart_flag",
    prodSalesStatus: "product_sales_status"
};

/**
 * OUP metaMap reversed
 * @type {{}}
 */
const METAMAP_PROTOR = swap(METAMAP_PROTO);

const mocks = {
    year: "2016",
    day: "10",
    month: "10",
    monthW: "Oct",
    state: "VIC",
    cost: "FREE"
}

/**
 * get Metadata value
 * @param {Result} result
 * @param metaData
 * @returns {string}
 */
const getMetadata = (result, metaData) => {
    var final;
    if (typeof METAMAP_PROTOR[metaData] == 'undefined') {
        throw `You are trying to call parameter ${metaData} which does not exist in const METAMAP_PROTO`;
    }
    if(typeof mocks[metaData] != 'undefined') {
        final = mocks[metaData];
    }
    else {
        final = result.metaData[METAMAP_PROTOR[metaData]];
        if (typeof final == 'undefined') {
            final = "";
        }
    }

    return final
}

/**
 * Get the result list from Funnelback json
 * @param data
 * @returns {*}
 */
const getResults = (data) => {
    return data.response.resultPacket.results;
};


/**
 * Get best bets from funnelback response
 * @param response
 * @returns {*}
 */
const getBestBets = (response) => {

    return response.resultPacket.bestBets;
}

/**
 * Set current query string options in the store
 * @param qstring
 */
const currentOptions = (store) => (qstring) => {
    let current_options = store('current_options');
    let qstringParts = qstring.split("=")
    current_options[qstringParts[0]] = qstringParts[1]
    store('current_options', current_options);
    return current_options;
}

/**
 * Remove param from the options in the store
 * @param param
 */
const currentOptionsRemove = (store) => (param) => {
    let current_options = store('current_options');
    delete current_options[param];
    store('current_options', current_options);
    return current_options;
}

/**
 * Add a facet query string part to the store
 * @param qstring
 */
const addFacetString = (currentOptions) => (qstring) => joinQuery(currentOptions(qstring));

/**
 * Remove a facet query string part from the store
 * @param qstring
 */
const removeFacetString = (currentOptionsRemove) => (qstring) => joinQuery(currentOptionsRemove(getKey(qstring)));

/**
 * @deprecated
 * @param store
 * @returns {*[]}
 */
const optionsFacetsFactory = (store) => {
    return [
        addFacetString(currentOptions(store)),
        removeFacetString(currentOptionsRemove(store))
    ]
}

/**
 *
 * @param literals
 * @param substs
 * @returns {string}
 */
function html(literals, ...substs) {
    return literals.raw.reduce((acc, lit, i) => {
        let subst = substs[i - 1];
        if (Array.isArray(subst)) {
            subst = subst.join('');
        }
        return acc + subst + lit;
    });
}

/**
 * Highlights a word 'qpart' in a string
 * @param qpart
 * @param str
 */
const highlight = (qpart, str) =>
    str.replace(new RegExp('(^'+qpart+' | '+qpart+'$| '+qpart+' )', 'gi'), "<strong>$1</strong>");

/**
 * Highlights all words in the query against matches in the title
 * @param query
 * @param str
 */
const highlightWords = (query, str) =>
    str ? qparts(query).reduce((accum, keyword) => highlight(keyword, accum), str) : "";

/**
 * Which view is selected?  List or Grid?
 * @param options
 * @param view
 * @returns {string}
 */
const viewSelected = (options, view) => {
    let currentView = qGet(options)('listType');
    if(currentView) {
        return view == currentView ? 'selected' : '';
    } else {
        return view == DEFAULT_LIST_TYPE ? 'selected' : '';
    }

}

/**
 * Moved
 * @param options
 */
const listTypeCssClass = (options) =>
    qGet(options)('listType') == 'grid' ? "col-xs-6 col-md-4 col-lg-3"  : "col-xs-6 col-md-12 col-lg-12";


/**
 * Moved
 * @param facets
 * @param FOR_SEARCH_TOOLS
 */
const includedFacets = (facets, FOR_SEARCH_TOOLS) =>
    facets.filter(facet =>
        FOR_SEARCH_TOOLS.indexOf(facet.name.toLowerCase()) == -1);

/**
 * Moved
 * @param selectedFacets
 */
const splitFacet = (selectedFacets) => (facet) =>
    selectedFacets.indexOf(facet.name.toLowerCase()) != -1

/**
 * Moved
 * @param facets
 * @param splitFacet
 */
const splitFacets = (facets, splitFacet) =>
    partition(facets, splitFacet)

/**
 * Moved
 * @param selectedFacets
 */
const normalizeFacets = (selectedFacets) =>
    selectedFacets.map(facet => facet.toLowerCase())

/**
 * Moved
 * @param valuesList
 */
const doSort = valuesList =>
    valuesList.sort(function valueSort(a, b) {
        var nameA = a.label.toUpperCase(); // ignore upper and lowercase
        var nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });

/**
 * Moved
 * @param facet
 * @param values
 * @returns {*}
 */
const orderFacetValues = (facet, values) => {
    facet.categories[0].values = values.filter((value, index) => index < 5).concat(
        doSort(values.filter((value, index) => index >= 5))
    )
    return facet;
}

/**
 * Moved
 * @param facet
 */
const maybeValues = facet =>
    facet.categories.length && facet.categories[0].values.length > 5 ? orderFacetValues(facet, facet.categories[0].values) : facet;

/**
 * Moved
 * @param facets
 */
const sortByOrder = (facets) =>
    facets.sort(function facetSort(a, b){
        let a1 = NORMALIZE_ORDER.indexOf(NORMALIZE_FUNC(a.name));
        let b1 = NORMALIZE_ORDER.indexOf(NORMALIZE_FUNC(b.name));
        a1 = a1 != -1 ? a1 : 100;
        b1 = b1 != -1 ? b1 : 100;
        if(a1 < b1) {
            return -1;
        }
        if(a1 > b1) {
            return 1;
        }
        return 0;
    });

/**
 * Moved
 * @param facets
 */
const orderValues = facets =>
    [facets[0], sortByOrder(facets[1].map(maybeValues))]

/**
 * Moved
 * @param facets
 * @param FOR_SEARCH_TOOLS
 * @param selectedFacets
 */
const sidebarFacets = (facets, FOR_SEARCH_TOOLS, selectedFacets) =>
    orderValues(
        splitFacets(
            includedFacets(facets, FOR_SEARCH_TOOLS),
            splitFacet(normalizeFacets(selectedFacets))
        )
    )

/**
 * Moved
 * @param categories
 */
const cleanCategories = (categories) =>
    categories.filter(category => category.label.substr(0, 1) != '%')


/**
 * @deprecated
 * @param FOR_SEARCH_TOOLS
 */
const isToolbarFacet = FOR_SEARCH_TOOLS => facet =>
    FOR_SEARCH_TOOLS.indexOf(facet.name.toLowerCase()) > -1;

const extractCategories = facets =>
    facets.length && facets[0].categories.length ? cleanCategories(facets[0].categories[0].values) : [];

/**
 * @deprecated
 * @param facets
 * @returns {*}
 */
const addTabs = (facets) => {
    if (!facets.length) {
        facets.push({
            "name": "Tabs",
            "categories": [
                {
                    "label": null,
                    "queryStringParamName": "f.Tabs|type",
                    "values": [
                        {
                            "data": "2",
                            "label": "products",
                            "count": 12,
                            "queryStringParam": "f.Tabs%7Ctype=products",
                            "constraint": "2"
                        },
                        {
                            "data": "2",
                            "label": "Events",
                            "count": 27,
                            "queryStringParam": "f.Tabs%7Ctype=Events",
                            "constraint": "2"
                        },
                        {
                            "data": "2",
                            "label": "Site content",
                            "count": 123,
                            "queryStringParam": "f.Tabs%7Ctype=Site+content",
                            "constraint": "2"
                        }
                    ],
                    "categories": []
                }
            ],
            "customData": {}
        })
    }
    return facets;
}

/**
 * @deprecated
 * @param facets raw facets from response
 * @param FOR_SEARCH_TOOLS
 */
const toolbarFacets = (facets, FOR_SEARCH_TOOLS) => {

    return extractCategories( facets.filter( isToolbarFacet(FOR_SEARCH_TOOLS) ) );

};

/**
 * @deprecated
 * @param templates
 */
const tabViewSelectorCls = (templates) => (query, location, options) => {
    let key = options['f.Tabs%7Ctype'].toLowerCase().replace(/\+/gi, "");
    return templates[key](query, location, options);
}

/**
 * Move to models.content
 * @param SORT_OPTIONS
 */
const addProductSort = (SORT_OPTIONS) =>
    SORT_OPTIONS.concat(
        [
            new Option('meta3', 'Lowest price'),
            new Option('dmeta3', 'Highest price')
        ]
    );

/**
 * Move to models.content
 * @param SORT_OPTIONS
 */
const addEventsSort = (SORT_OPTIONS) =>
    SORT_OPTIONS.filter(x => x.name.indexOf('title') == -1).concat(
        [
            new Option('metad', 'Title A-Z'),
            new Option('dmetad', 'Title Z-A')
        ]
    );

/**
 * Move to models.content
 * @param SORT_OPTIONS
 * @param options
 */
const maybeEvents = (SORT_OPTIONS, options) =>
    options[TAB_FACET] == 'events' ? addEventsSort(SORT_OPTIONS) : SORT_OPTIONS;

/**
 * Move to models.content
 * @param SORT_OPTIONS
 * @param options
 */
const getSortOptions = (SORT_OPTIONS, options) =>
    options[TAB_FACET] == 'products' ? addProductSort(SORT_OPTIONS) : maybeEvents(SORT_OPTIONS, options);

/**
 * Media Query hack
 * @type {{isMobileOnly: string, isTabletOnly: string, isDesktopOnly: string, isTabletAndDesktop: string}}
 */
const mediaQueryObject = {
    isMobileOnly: 'is-mobile-only',
    isTabletOnly: 'is-tablet-only',
    isDesktopOnly: 'is-desktop-only',
    isTabletAndDesktop: 'is-tablet-and-desktop'
};

Object.freeze(mediaQueryObject);

/**
 * Use CSS to determine device
 * @param visible
 * @param mediaQueryEls
 */
const mediaQueryCls = (visible, mediaQueryEls) => () => {
    let mobileVisible = visible(mediaQueryEls.isMobileOnlyEl);
    let tabletVisible = visible(mediaQueryEls.isTabletOnlyEl);
    let desktopVisible = visible(mediaQueryEls.isDesktopOnlyEl);
    if(mobileVisible) {
        return mediaQueryObject.isMobileOnly;
    }
    else if(!mobileVisible && tabletVisible && !desktopVisible) {
        return mediaQueryObject.isTabletOnly;
    }
    else if(!mobileVisible && tabletVisible && desktopVisible) {
        return mediaQueryObject.isTabletAndDesktop;
    }
    else {
        return 'global';
    }

}

/**
 * Display value
 * @param window
 */
const displayValueCls = window =>
    el =>
        window.getComputedStyle(el).getPropertyValue('display') == 'block';

/**
 * Factory for mediaQueryCls
 * @param window
 * @param document
 */
const mediaQueryFactory = (window, document) => {
    return mediaQueryCls(displayValueCls(window), {
        isMobileOnlyEl: document.getElementById(mediaQueryObject.isMobileOnly),
        isTabletAndDesktopEl: document.getElementById(mediaQueryObject.isTabletAndDesktop),
        isTabletOnlyEl: document.getElementById(mediaQueryObject.isTabletOnly),
        isDesktopOnlyEl: document.getElementById(mediaQueryObject.isDesktopOnly)
    })
}

/**
 * Remove class from element
 * @param el
 * @param cls
 */
const removeClass = (el, cls) =>
    el.className = el.className.replace(new RegExp(" " + cls, 'gi'), '')

/**
 * Add class to element
 * @param el
 * @param cls
 */
const addClass = (el, cls) =>
    el.className = removeClass(el, cls) + " " + cls

export {
    html,
    highlight,
    highlightWords,
    viewSelected,
    listTypeCssClass,
    sidebarFacets,
    toolbarFacets,
    cleanCategories,
    tabViewSelectorCls,
    getSortOptions,
    mediaQueryFactory,
    mediaQueryObject,
    METAMAP_PROTO,
    METAMAP,
    METAMAP_PROTOR,
    getMetadata,
    getResults,
    getBestBets,
    optionsFacetsFactory,
    addClass,
    removeClass
}
