import {Option} from './types'
/**
 * OUP facet orders
 * @type {string[]}
 */
const ORDER = ['Tabs', 'Search within', 'Educational Level', 'Subject', 'Location', 'Format', 'Year Level', 'State Country', 'Series', 'Author', 'Age Level', 'Text Level', 'Edition', 'Year Published']

const FOR_SEARCH_TOOLS = ['tabs']

const COLLECTION = 'oup-meta';

const CARTID = '918';

const TAB_FACET = 'f.Tabs%7Ctype';

const DEFAULT_TAB_FACET = 'products';

const DEFAULT_LIST_TYPE = 'grid';

const SORT_OPTIONS = [
    new Option('relevance', 'relevance'),
    new Option('date', 'Newest'),
    new Option('adate', 'Oldest'),
    new Option('title', 'Title A-Z'),
    new Option('dtitle', 'Title Z-A')
];

const NORMALIZE_FUNC = item => item.toLowerCase().replace(/\//gi, ' ');

const NORMALIZE_ORDER = ORDER.map(NORMALIZE_FUNC);

const DEFAULT_FACETS = ['educational level', 'subject', 'location', 'format', 'year level'];

const PROTOCOL = typeof window != 'undefined' ? window.location.protocol : "https:";

const DOMAIN = `${PROTOCOL}//oup-funnelback.clients.squiz.net`;

const COMPUTED = 'XYZ123';

export {
    ORDER,
    FOR_SEARCH_TOOLS,
    COLLECTION,
    CARTID,
    SORT_OPTIONS,
    TAB_FACET,
    DEFAULT_LIST_TYPE,
    NORMALIZE_ORDER,
    NORMALIZE_FUNC,
    DEFAULT_FACETS,
    DOMAIN,
    DEFAULT_TAB_FACET,
    COMPUTED
}