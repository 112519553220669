/**
 *
 *
 *
 *
 * Types
 */

import {slugify} from './utils'

/**
 * Type of BestBet for funnelback JSON output
 * @param {string} trigger - Example: "secondary dictionary"
 * @param {string} link - Example: "http://www.oup.com.au/dictionaries_and_reference/Australian_Dictionaries_and_Thesauruses/secondary"
 * @param {string} title - Example: "Australian Secondary Dictionaries & Thesauruses"
 * @param {string} description - Example: "Oxford's range of Australian secondary school dictionaries and thesauruses have been created and edited according to the needs of each age level and to the demands of the curriculum, from the first years of secondary school through to Year 12."
 * @param {string} clickTrackingUrl - Example: "/s/redirect?&collection=oup-products-xml&url=http%3A%2F%2Fwww.oup.com.au%2Fdictionaries_and_reference%2FAustralian_Dictionaries_and_Thesauruses%2Fsecondary&index_url=http%3A%2F%2Fwww.oup.com.au%2Fdictionaries_and_reference%2FAustralian_Dictionaries_and_Thesauruses%2Fsecondary&auth=06kqBc3t8Fbaaw82odyWMQ&type=FP&profile=_default"
 * @param {Object} customData
 */
class BestBet {
    constructor({
        trigger,
        link,
        title,
        description,
        clickTrackingUrl,
        customData
    }) {
        this.trigger = trigger;
        this.link = link;
        this.title = title;
        this.description = description;
        this.clickTrackingUrl = clickTrackingUrl;
        this.customData = customData;
    }
}

/**
 * Type of Result for funnelback JSON output
 * @param {string} rank - Example: 1
 * @param {string} score - Example: 1000
 * @param {string} title - Example: "Oxford School Dictionary 2016"
 * @param {string} collection - Example: "oup-products-xml"
 * @param {string} component - Example: 0
 * @param {Object} collapsed
 * @param {string} liveUrl - Example: "http://www.oup.com.au/titles/unclassified/9780192743503"
 * @param {Object} summary
 * @param {string} cacheUrl - Example: "/s/cache?collection=oup-products-xml&doc=product9780192743503.xml&off=0&len=-1&url=http%3A%2F%2Fwww.oup.com.au%2Ftitles%2Funclassified%2F9780192743503&profile=_default"
 * @param {string} date - Example: 1461074400000
 * @param {string} fileSize - Example: 3786
 * @param {string} fileType - Example: "xml"
 * @param {string} tier - Example: 1
 * @param {string} docNum - Example: 32217
 * @param {Object} exploreLink
 * @param {Object} kmFromOrigin
 * @param {Object} metaData
 * @param {Array} tags
 * @param {Object} quickLinks
 * @param {string} displayUrl - Example: "http://www.oup.com.au/titles/unclassified/9780192743503"
 * @param {string} clickTrackingUrl - Example: "/s/redirect?rank=1&collection=oup-products-xml&url=http%3A%2F%2Fwww.oup.com.au%2Ftitles%2Funclassified%2F9780192743503&index_url=http%3A%2F%2Fwww.oup.com.au%2Ftitles%2Funclassified%2F9780192743503&auth=XUsOCHVfY6BF4mgg4cLWRA&query=dictionary&profile=_default"
 * @param {Object} explain
 * @param {string} indexUrl - Example: "http://www.oup.com.au/titles/unclassified/9780192743503"
 * @param {Array} gscopesSet
 * @param {Object} customData
 */
class Result {
    constructor({
        rank,
        score,
        title,
        collection,
        component,
        collapsed,
        liveUrl,
        summary,
        cacheUrl,
        date,
        fileSize,
        fileType,
        tier,
        docNum,
        exploreLink,
        kmFromOrigin,
        metaData,
        tags,
        quickLinks,
        displayUrl,
        clickTrackingUrl,
        explain,
        indexUrl,
        gscopesSet,
        customData
    }) {
        this.rank = rank;
        this.score = score;
        this.title = title;
        this.collection = collection;
        this.component = component;
        this.collapsed = collapsed;
        this.liveUrl = liveUrl;
        this.summary = summary;
        this.cacheUrl = cacheUrl;
        this.date = date;
        this.fileSize = fileSize;
        this.fileType = fileType;
        this.tier = tier;
        this.docNum = docNum;
        this.exploreLink = exploreLink;
        this.kmFromOrigin = kmFromOrigin;
        this.metaData = metaData;
        this.tags = tags;
        this.quickLinks = quickLinks;
        this.displayUrl = displayUrl;
        this.clickTrackingUrl = clickTrackingUrl;
        this.explain = explain;
        this.indexUrl = indexUrl;
        this.gscopesSet = gscopesSet;
        this.customData = customData;
    }
}

/**
 * Type of Category for funnelback JSON output
 * @param {string} name - Example: "type"
 * @param {string} more - Example: 0
 * @param {Object} moreLink
 * @param {string} fewerLink - Example: "?collection=oup-products-xml&origin=-27.0%2C133.0&profile=_default&query=dictionary"
 * @param {Array.<Cluster>} clusters
 */
class Category {
    constructor({
        name,
        more,
        moreLink,
        fewerLink,
        clusters
    }) {
        this.name = name;
        this.more = more;
        this.moreLink = moreLink;
        this.fewerLink = fewerLink;
        this.clusters = clusters;
    }
}

/**
 * Type of CategoryValue for funnelback JSON ouput
 * @param {string} data
 * @param {string} label
 * @param {string} count
 * @param {string} queryStringParam
 * @param {string} constraint
 */
class CategoryValue {
    constructor({
        data,
        label,
        count,
        queryStringParam,
        constraint
    }) {
        this.data = data;
        this.label = label;
        this.count = count;
        this.queryStringParam = queryStringParam;
        this.constraint = constraint;
    }
}

/**
 * Type of Response for funnelback JSON output
 * @param {Object} resultPacket
 * @param {string} returnCode - Example: 0
 * @param {Array.<Facet>} facets
 * @param {Object} customData
 * @param {Object} optimiserModel
 * @param {Object} entityDefinition
 * @param {Object} curator
 * @param {Object} performanceMetrics
 */
class Response {
    constructor({
        resultPacket,
        returnCode,
        facets,
        customData,
        optimiserModel,
        entityDefinition,
        curator,
        performanceMetrics
    }) {
        this.resultPacket = resultPacket;
        this.returnCode = returnCode;
        this.facets = facets;
        this.customData = customData;
        this.optimiserModel = optimiserModel;
        this.entityDefinition = entityDefinition;
        this.curator = curator;
        this.performanceMetrics = performanceMetrics;
    }
}


/**
 * Type of Facet for funnelback JSON output
 * @param {string} name - Example: "Educational level"
 * @param {Array.<Category>} categories
 * @param {Object} customData
 */
class Facet {
    constructor({
        name,
        categories,
        customData
    }) {
        this.name = name;
        this.categories = categories;
        this.customData = customData;
    }
}

/**
 * Basic Link Type for Views
 */
class Link  {
    constructor(name, uri) {
        this.name = name;
        this.uri = BASE + uri;
    }

};


/**
 * Basic Link Type for Views
 */
class Option {
    constructor(name, label) {
        this.name = name;
        this.label = label;
    }

};

/**
 * Breadcrumb type for breadcrumbs view
 */
class Breadcrumb {
    constructor(title, facetTitle, qstring) {
        this.title = title;
        this.facetTitle = facetTitle;
        this.slug = slugify(title);
        this.qstring = qstring;
    }
}

export {
    BestBet,
    Result,
    Category,
    CategoryValue,
    Response,
    Facet,
    Link,
    Breadcrumb,
    Option
};