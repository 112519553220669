/**
 *
 *
 *
 *
 * State
 */

import {getKey, normalizeFacets} from './queryutils'

/**
 * A generic object data store passed and accessed via a function
 * @param data
 */
const dataStore = (data = {}) => (key, value = null) => {
    if (value !== null) {
        data[key] = value;
    }
    let rval = data[key];
    if (typeof rval == 'undefined') {
        rval = null;
    }
    return rval;
};

/**
 * Is the option currently stored in the dataStore
 * @param key
 */
const isOption = store => key =>
    typeof store('current_options')[key] != 'undefined';

/**
 * Check if the query string part is in the options already
 * @param str
 */
const isOptionFromQstringPart = isOption => str =>
    isOption(getKey(str));

/**
 * initialise facets from the start window.location.search
 * @param store
 */
const initStore = getWindow => {
    /**
     * dataStore instance
     * @param store
     */
    let store = dataStore();
    store('current_query', '');
    store('current_options', {});
    store('expand_filters', false);
    store('facet_expand', {})
    let search = getWindow().location.search;
    let subSearch = search.substr(0, 1) == '?' ? search.substr(1).split('&') : search.split('&');
    store(
        'facets',
        normalizeFacets(subSearch.filter(qstringPart => qstringPart.substr(0, 2) == 'f.'))
    )
    let _isOption = isOption(store);
    store('results_set', []);
    return [
        _isOption,
        store
    ]
};


export default initStore;