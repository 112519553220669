const header = {};

/**
 * View for mobile header when facets are active
 */
header.mobile = () => `
    <div class="padder">
        <div class="row">
            <div class="col-xs col-md-3 col-lg-2">
                 <h2 class="filter_h2">Filters</h2>           
            </div>
            <div class="col-xs col-md-3 last-sm col-lg-offset-1 col-lg-3 nfp">
                <div class="icons-wrapper icon-tools desktop-hide">
                    <div class="icon-tools__filter" style="display: block;">
                        <a href="javascript:void(0);" onclick="return ev(this, 'facet')" class="icon icon-close" data-qstring="filters=close">
                            <span class="visuallyhidden">Close Filters</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;

export default header;