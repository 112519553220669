"use strict";

import { newObj, decode } from './utils'

import {
    getKey,
    joinQuery,
    normalizeFacets
} from './queryutils'


import {
    html,
    highlightWords,
} from './viewutils'

import {
    TAB_FACET,
    COLLECTION
} from './config'

import header from './views/header';

import main from './views/main';

import axios from 'axios';

import handlers from './handlers';

const events = {};

const registry = {};

const sidebar = {};


/**
 * Wait till an element is on the dom then fire a function
 * @param document
 * @param id
 * @param fn
 * @returns {number}
 */
const ready = (document, id, fn) => {

    if (!document.getElementById(id)) {

        return setTimeout(() => ready(document, id, fn), 100);
    }
    else {
        fn(id);
    }
}

/**
 * Set a dom elements dataset
 * @param el
 * @param key
 */
const dataset = (el, key) =>
    typeof el.dataset != 'undefined' ? el.dataset[key] : el.getAttribute('data-' + key);

/**
 * Remove pagination from the query string
 * @param amodel
 */
const removePagination = (amodel) =>
    amodel.qStringFacetRemove('start_rank=1');

/**
 * Remove all options from the query string except query
 * @param amodel
 * @returns {*}
 */
const removeAllOptions = (amodel) => {
    let facets = [].concat(amodel.store('facets'));

    removePagination(amodel);
    let current_options = amodel.store('current_options');
    let new_options = {}
    Object.keys(current_options).forEach(
        option => {
            if (option.substr(0, 2) != 'f.' && option != 'pageTitle') {
                new_options[option] = current_options[option];
            }
        }
    )

    amodel.store('facets', []);
    return amodel.store('current_options', new_options);
}

/**
 * Should a facet show all categories or only some
 * @param document
 * @param facets
 * @param facetExpand
 * @param moreLess
 */
sidebar.moreLess = (document, facets, facetExpand, moreLess) => id => {

    //ready is required for all elements ( 1 per element )
    if (moreLess == 'Less') {
        let facetContent = document.getElementById('filters__content');
        if (facetContent) {
            facetContent.className = facetContent.className + " show-hidden-facets";
        }

    }
    for (let key in facetExpand) {
        if (facetExpand[key]) {
            let facet = document.getElementById(key);
            if (facet) {
                facet.className = facet.className + " show-hidden";
                let label = facet.getElementsByClassName('filters__more-button');
                if (label && label.length) {
                    label[0].textContent = 'LESS';
                }
            }
        }
    }
    let availableFacets = facets.select.filter(facet => facet.categories.length);
    let el = document.getElementById(id);
    let refineBy = document.getElementById('refine__by')
    if (availableFacets.length < 5) {
        if (el) {
            el.className = el.className + " hide";
        }
        if (refineBy) {
            refineBy.className = refineBy.className + " hide";
        }

    }
    else {
        if (el) {
            el.className = el.className.replace(/ hide/gi, "");
        }
        if (refineBy) {
            refineBy.className = refineBy.className.replace(/ hide/gi, "");
        }
    }
};

/**
 * A function for animating showing facets in mobile
 * @deprecated
 * @param document
 */
sidebar.animateOpen = document => id => {
    let el = document.getElementById(id);
    el.className = el.className.replace(/ mobile-facets-show/gi, '') + ' mobile-facets-show';
}

/**
 * The auto complete element on the dom
 * @param document
 */
const autocompleteEl = document =>
    document.getElementById('autocomplete-results');

/**
 * Keep as one line because otherwise there are issues with activeElement.nextElementSibling
 * THis could probably be solved by a replace regex of tabs/new lines
 * @param query
 */
const autoSuggestLiCls = query => item =>
    `<li role="menuitem"><a href="javascript:void(0)" onclick="return ev(this, 'autoSearch')" class="autosuggest-item">${highlightWords(query, item)}</a></li>`;

/**
 * Handle autocomplete results returned from funnelback
 * @param amodel
 * @param autosuggestQuery
 */
const autosuggestHandler = (amodel, autosuggestQuery) => data => {
    let autoSuggestLi = autoSuggestLiCls(autosuggestQuery);
    let el = autocompleteEl(amodel.document);
    el.className = el.className.replace(/hide/gi, "");
    //data.data = data.data.length ? data.data : ['No suggestion found.']
    if(data.data.length) {
        el.innerHTML = html`<ul>${Array.from(new Set(data.data)).map(autoSuggestLi)}</ul>`
    }

}



/**
 * An event handler that is active when a user has focus on the search form input.
 * Listens for up arrow or down arrow and adjusts highlight and focus
 * @param e
 * @returns {boolean}
 */
const keydownListener = e => {
    if (e.keyCode == 40) {
        e.preventDefault();
        if (document.activeElement.id == "site-search_input") {
            document.activeElement.nextElementSibling.getElementsByClassName('autosuggest-item')[0].focus();
        }
        else if (document.activeElement.className == 'autosuggest-item') {
            let nextSibling = document.activeElement.parentElement.nextSibling;
            if (nextSibling) {
                nextSibling.children[0].focus();
            }
            else {
                document.getElementById("site-search_input").nextElementSibling.getElementsByClassName('autosuggest-item')[0].focus();
            }
        }
    }
    else if (e.keyCode == 38) {
        if (document.activeElement.id == "site-search_input") {
            let els = document.activeElement.nextElementSibling.getElementsByClassName('autosuggest-item');
            els[els.length - 1].focus();
        }
        else if (document.activeElement.className == 'autosuggest-item') {
            let previousSibling = document.activeElement.parentElement.previousSibling;
            if (previousSibling) {
                previousSibling.children[0].focus();
            }
            else {
                let els = document.getElementById("site-search_input").nextElementSibling.getElementsByClassName('autosuggest-item');
                els[els.length - 1].focus();
            }
        }
    }
    return false;
}

/**
 * Remove autocomplete dropdown
 * @param amodel
 */
const clearAutocomplete = amodel => {
    let el = autocompleteEl(amodel.document);
    el.className = el.className.replace(/hide/gi, '') + "hide";
    el.innerHTML = "";
    amodel.document.removeEventListener("keydown", keydownListener);
}

/**
 * If the user clicks out of Autosuggest close down autosuggest
 * @param amodel
 */
const clickOutOfSuggest = amodel => e => {
    if(!amodel.document.getElementById('search-form').contains(e.target)) {
        clearAutocomplete(amodel);
        amodel.document.removeEventListener("click", amodel.clickOutOfSuggest);
    }

}

/**
 * Confirms if the elements with ids sidebar,breadcrumbs,content-v2 exist on the dom
 * If they don't exist the elements are added via main()
 * @param amodel
 * @param router
 * @param search
 * @returns {boolean}
 */
const confirmContainers = (amodel, router, search) => {
    router.navigate(search);
    if (!amodel.ELS.sidebar || !amodel.ELS.content || !amodel.ELS.breadcrumbs) {
        amodel.document.getElementById('main').innerHTML = main();
        ready(amodel.document, 'content-v2', function (id) {
            amodel.ELS.sidebar = amodel.document.getElementById('sidebar');
            amodel.ELS.breadcrumbs = amodel.document.getElementById('breadcrumbs');
            amodel.ELS.content = amodel.document.getElementById('content-v2');
            router.navigate(search);
        });
        return false;
    }
    else {
        return true;
    }
}

/**
 * Runs a search by eventually navigating to a new search route
 * @param amodel
 * @param router
 * @param query
 * @returns {boolean}
 */
const runSearch = (amodel, router, query) => {
    let current_options = amodel.store('current_options');
    amodel.store('current_options', null);
    clearAutocomplete(amodel);
    let qparams = {query};
    qparams[TAB_FACET] = typeof current_options[TAB_FACET] != 'undefined' ? current_options[TAB_FACET] : 'products';
    let nquery = '/search/' + joinQuery(qparams);
    if (confirmContainers(amodel, router, nquery)) {
        router.navigate(nquery);
    }
    return false;
}

registry.confirmContainers = confirmContainers;

/**
 * Used for basic routes that do not require query string adjustment
 * The full url is passed in obj.link
 * @param amodel
 * @param router
 * @param obj
 * @returns {boolean}
 */
registry.reroute = (amodel, router, obj) => {
    let nrequest = dataset(obj.link, 'request');
    let activeItem = amodel.document.getElementById('navbar__dropdown').getElementsByClassName('navbar__item--active')[0];

    if (activeItem) {
        let mediaQuery = amodel.mediaQuery();
        //  Use jQuery to close mobile nav
        if(mediaQuery == 'is-mobile-only' || mediaQuery == 'is-tablet-only') {
            // amodel.window.$('.icon-navicon.active').trigger('click');
        }
        else {
            // Close menu on tablet and desktop
            activeItem.className = activeItem.className.replace(/ navbar__item--active/gi, "");
        }
    }
    if (nrequest.indexOf('/search/') != -1) {
        obj.event.preventDefault();
        let search = nrequest.replace(new RegExp(amodel.window.location.origin, 'gi'), "");
        if (confirmContainers(amodel, router, search)) {
            router.navigate(search);
            amodel.window.document.getElementById( "site-search_input").value =  ""
        }
        return false;
    }
    else {
        return true;
    }
};

/**
 * Runs the searchHandler but does not change the query string
 * @param amodel
 * @param router
 * @param link
 */
registry.update = (amodel, router, link) => {
    router.navigate(dataset(link, 'uri'), true, true);
};

/**
 * Event handler for sort option changes
 * @param amodel
 * @param router
 * @param select
 */
registry.sort = (amodel, router, select) => {
    var selected = select.options[select.selectedIndex].value;
    let current_options = amodel.store('current_options');
    current_options.sort = selected;
    amodel.store('current_options', current_options);
    removePagination(amodel);
    router.navigate(amodel.addPath(joinQuery(current_options)))
};

/**
 * Event handler for new queries
 * @param amodel
 * @param router
 * @param query
 * @returns {*}
 */
registry.newQuery = (amodel, router, query) => {
    return router.navigate('/search/' + joinQuery({query}));
};


/**
 * Possibly deprecated
 * @param amodel
 * @param router
 * @param link
 * @returns {boolean}
 */
registry.autoSearch = (amodel, router, link) => {
    let query = link.textContent.trim().replace(/\+/gi, ' ');
    amodel.document.getElementById("site-search_input").value = decode(query);
    return runSearch(amodel, router, query);
};

/**
 * Begin auto suggestion
 * @param amodel
 * @param router
 * @param inputField
 */
registry.searchInputFocus = (amodel, router, inputField) => {
    inputField.value = "";
    amodel.document.addEventListener("keydown", keydownListener);
    if(typeof amodel.clickOutOfSuggest == 'undefined') {
        amodel.clickOutOfSuggest = clickOutOfSuggest(amodel);
    }
    amodel.document.addEventListener('click', amodel.clickOutOfSuggest);
};

/**
 * Event handler for new queries
 * @param amodel
 * @param router
 * @param form
 * @returns {boolean}
 */
registry.search = (amodel, router, form) => {
    let query = form.querySelector('input').value.trim().replace(/ /g, "+");
    return runSearch(amodel, router, query);
};

/**
 * Event handler for changes in the search input form
 * @param amodel
 * @param router
 * @param obj
 */
registry.autosuggest = (amodel, router, obj) => {
    let queryField = obj.obj;
    if (obj.event.keyCode != 13) {
        axios.get(
            amodel.QUERY_DOMAIN + "/s/suggest.json?collection=" +
            COLLECTION +
            "&show=10&sort=0&alpha=0.81&fmt=json&partial_query=" + queryField.value
        ).then(autosuggestHandler(amodel, queryField.value))
            .catch(handlers.response.error);
    }
    else {
        amodel.document.removeEventListener("keydown", keydownListener);
        queryField.blur();

    }


};

/**
 * Add facet and options to the query string
 * @param amodel
 * @param router
 * @param link
 * @returns {boolean}
 */
registry.facet = (amodel, router, link) => {
    let qstring = dataset(link, 'qstring');

    if (getKey(qstring) == TAB_FACET) {
        removeAllOptions(amodel);
    }
    amodel.store(
        'facets',
        amodel.store('facets').concat(normalizeFacets(qstring))
    )
    removePagination(amodel);
    let options = amodel.store('current_options');
    let device = typeof options['device'] != 'undefined' ? options['device'] : false;
    if (qstring == 'filters=open' && device == 'is-mobile-only') {

        amodel.ELS.header_main.className = amodel.ELS.header_main.className + " mobile-hide";
        amodel.ELS.header_mobile.innerHTML = header.mobile();
    }
    else if (qstring == 'filters=close' && device == 'is-mobile-only') {
        // good enough
        amodel.ELS.header_main.className = amodel.ELS.header_main.className.replace(/ mobile-hide/gi, '');
        amodel.ELS.header_mobile.innerHTML = '';
        amodel.window.scrollTo(0, 120);

    }
    router.navigate(amodel.qStringFacetAdd(qstring));
    return false;
};

/**
 * Event handler for mobile drop down
 * @param amodel
 * @param router
 * @param select
 * @returns {boolean}
 */
registry.selectFacet = (amodel, router, select) => {
    var selected = select.options[select.selectedIndex].value;
    removeAllOptions(amodel);
    amodel.store(
        'facets',
        amodel.store('facets').concat(normalizeFacets(selected))
    )
    removePagination(amodel);
    router.navigate(amodel.qStringFacetAdd(selected));
    return false;
};

/**
 * Remove a facet or option from the query string
 * @param amodel
 * @param router
 * @param link
 * @returns {boolean}
 */
registry.remove = (amodel, router, link) => {
    amodel.store(
        'facets',
        amodel.store('facets').filter(qstringPart => qstringPart != dataset(link, 'remove'))
    )
    removePagination(amodel);
    let options = amodel.store('current_options');
    let url = amodel.qStringFacetRemove(dataset(link, 'remove'))
    if(typeof options['pageTitle'] != 'undefined') {
        url = amodel.qStringFacetRemove( 'pageTitle=' + options['pageTitle'] );
    }
    router.navigate(url)
    return false;
};

/**
 * Event handler for mobile search field drop down
 * @param amodel
 * @param router
 * @param link
 */
registry.toggleSearch = (amodel, router, link) => {
    if (link.className.indexOf('icon-search') != -1) {
        link.className = link.className.replace(/icon-search/gi, 'icon-close');
    }
    else {
        link.className = link.className.replace(/icon-close/gi, 'icon-search');
    }
};

/**
 * Event handler for listType list or grid
 * @param amodel
 * @param router
 * @param link
 */
registry.listType = (amodel, router, link) => {
    router.navigate(amodel.qStringFacetAdd('listType=' + dataset(link, 'view')));
};

/**
 * Event handler for pagination button - add pages
 * @param amodel
 * @param router
 * @param link
 */
registry.pagination = (amodel, router, link) => {
    amodel.document.getElementById('pagination-text').innerHTML = 'Loading...';
    router.navigate(amodel.qStringFacetAdd('start_rank=' + dataset(link, 'next')));
};

/**
 * Event handler for remove all options
 * @param amodel
 * @param router
 * @param link
 * @returns {boolean}
 */
registry.removeAll = (amodel, router, link) => {
    router.navigate(amodel.addPath(joinQuery(removeAllOptions(amodel))));
    return false;
};

/**
 * Handler for facet expand or collapse
 * @param amodel
 * @param router
 * @param link
 */
registry.animateFacet = (amodel, router, link) => {
    let facet = amodel.document.getElementById(link.id);
    let facetExpand = amodel.store('facet_expand');
    if (link.main.textContent == 'MORE') {
        link.main.textContent = "LESS";
        facet.className = facet.className + " show-hidden";
        facetExpand[link.id] = true;

    }
    else {
        link.main.textContent = "MORE";
        facet.className = facet.className.replace(/ show-hidden/gi, "");
        amodel.store(link.id, null);
        facetExpand[link.id] = null;
    }
};

/**
 * Event handler for expanding or collapsing all facets
 * @param amodel
 * @param router
 * @param link
 */
registry.expandFilters = (amodel, router, link) => {
    let label = link.getElementsByClassName('label')[0];
    let facetContent = amodel.document.getElementById('filters__content');

    if (!amodel.store('expand_filters')) {

        facetContent.className = facetContent.className + " show-hidden-facets";


        label.textContent = 'Less Filters';
        amodel.store('expand_filters', true);
    }
    else {

        label.textContent = 'More Filters';
        facetContent.className = facetContent.className.replace(/ show-hidden-facets/gi, "");
        amodel.store('expand_filters', false);

        amodel.window.scrollTo(0, 0);
    }
};

/**
 * Event handler for window size changes
 * @param router
 * @param store
 * @param mediaQuery
 */
const windowChangeCls = (router, store, mediaQuery) => () => {
    let mediaQueryResult = mediaQuery();
    if (store('device') != mediaQueryResult && store('current_query')) {
        store('device', mediaQueryResult);
        let options = store('current_options');
        options['device'] = mediaQueryResult;
        if (mediaQueryResult == 'is-mobile-only') {
            delete options['listType'];
        }
        store('current_options', options);
        let query = newObj(options);
        query['query'] = store('current_query');
        router.navigate('/search/' + joinQuery(query));
    }
};


/**
 * Dom event handler manager
 * @param router
 * @param events
 */
events.manager = (router, eventMap, amodel) => (obj, eventName) => {
    amodel.store('previous_query', amodel.store('current_query'))
    amodel.store('previous_options', newObj(amodel.store('current_options')));
    return eventMap[eventName](amodel, router, obj);
};

/**
 * Generate events.manager
 * @param router
 * @param eventMap
 * @param amodel
 */
events.factory = (router, eventMap, amodel) => {
    let windowChange = windowChangeCls(router, amodel.store, amodel.mediaQuery);
    amodel.window.addEventListener("orientationchange", windowChange);
    amodel.window.addEventListener("resize", windowChange);
    return events.manager(router, eventMap, amodel);
}

events.sidebar = sidebar;

events.registry = registry;

events.ready = ready;

export default events;