const handlers = {};
const response = {};

/**
 * Handler for errors on axios catch
 * @param error
 */
response.error = error => {
    console.log('An error occured');
    console.log(error);
}

handlers.response = response;

export default handlers;