import partition from 'lodash.partition'
import {FOR_SEARCH_TOOLS, NORMALIZE_ORDER, NORMALIZE_FUNC} from '../config'
import {check, decodePlus, capitalizeAll} from '../utils';
import {beforeFacetProcessing} from '../custom/facets';
"use strict";

/**
 * Remove categories with funny names
 * @param categories
 */
const cleanCategories = categories =>
    categories.filter(category => category.label.substr(0, 1) != '%')

/**
 * Clean facetItems categories
 * @param facetItem
 * @returns {*}
 */
const cleanFacetCategories = facetItem => {
    facetItem.catValues = cleanCategories(facetItem.categories[0].values);
    return facetItem;
}

/**
 * Return only included facets
 * @param facets
 * @param FOR_SEARCH_TOOLS
 */
const includedFacets = (facets, FOR_SEARCH_TOOLS) =>
    facets.filter(facet =>
        FOR_SEARCH_TOOLS.indexOf(facet.name.toLowerCase()) == -1);

/**
 * Used as callback to partition facets into selected and not selected
 * @param selectedFacets
 */
const splitFacet = selectedFacets => facet =>
    selectedFacets.indexOf(facet.name.toLowerCase()) != -1

/**
 * Split the facets into two groups of selected and not selected
 * @param facets
 * @param splitFacet
 */
const splitFacets = (facets, splitFacet) =>
    partition(facets, splitFacet)

/**
 * Make facets to lowercase
 * @param selectedFacets
 */
const normalizeFacets = selectedFacets =>
    selectedFacets.map(facet => facet.toLowerCase())

/**
 * Sort facet categories alphabetically
 * @param valuesList
 */
const doSort = valuesList =>
    valuesList.sort(function valueSort(a, b) {
        var nameA = a.label.toUpperCase(); // ignore upper and lowercase
        var nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });

/**
 * Show top 5 categories of a facet by count then remaining alphabetically
 * @param facet
 * @param values
 * @returns {*}
 */
const orderFacetValues = (facet, values) => {
    facet.categories[0].values = values.filter((value, index) => index < 5).concat(
        doSort(values.filter((value, index) => index >= 5))
    )
    return facet;
}

/**
 * Order categories of a facet if there are greater than five categories
 * @param facet
 */
const maybeValues = facet =>
    facet.categories.length && facet.categories[0].values.length > 5 ? orderFacetValues(facet, facet.categories[0].values) : facet;

/**
 * Sort Facets by a config
 * @param facets
 */
const sortByOrder = facets =>
    facets.sort(function facetSort(a, b){
        let a1 = NORMALIZE_ORDER.indexOf(NORMALIZE_FUNC(a.name));
        let b1 = NORMALIZE_ORDER.indexOf(NORMALIZE_FUNC(b.name));
        a1 = a1 != -1 ? a1 : 100;
        b1 = b1 != -1 ? b1 : 100;
        if(a1 < b1) {
            return -1;
        }
        if(a1 > b1) {
            return 1;
        }
        return 0;
    });

/**
 * Remove facets with no categories
 * @param facets
 */
const filterEmptyCategories = facets =>
    facets.filter(facet => facet.categories.length).map(cleanFacetCategories);

/**
 * Make partitioned facets into an object categorised as facets with remove options and facets with select options
 * @param facets
 * @returns {{remove: *, select}}
 */
const orderValues = facets => {
    return  {
        remove: facets[0],
        select: sortByOrder(facets[1].map(maybeValues))
    }
}

/**
 * Get the sidebar facets with categories, sorted correctly and grouped by selected and not selected
 * @param facets
 * @param FOR_SEARCH_TOOLS
 * @param selectedFacets
 */
const sidebarFacets = (facets, FOR_SEARCH_TOOLS, selectedFacets) =>
    orderValues(
        splitFacets(
            includedFacets(beforeFacetProcessing(facets), FOR_SEARCH_TOOLS),
            splitFacet(normalizeFacets(selectedFacets))
        )
    )

/**
 * Is mobile device only
 * @param options
 */
const isMobileOnly = options =>
     typeof options.device != 'undefined' && options.device == 'is-mobile-only' ? true : false;

/**
 * Used for mobile animation show/hide facet
 * @param options
 * @returns {*}
 */
const mobileDesktopClasses = options => {
    //model.isMobileOnly && model.isOpen ? ' mobile-facets animated fadeIn' : ' mobile-hide tablet-show filter__panel'
    let isMobile = isMobileOnly(options);
    let isOpen = check(options, 'filters', 'open');

    if(!isMobile) {
       return 'mobile-hide tablet-show filter__panel';
    }
    else if(isMobile && isOpen) {
       return 'mobile-facets animated fadeIn';
    }
    else if(isMobile && !isOpen) {
        return 'mobile-hide tablet-show filter__panel'
    }

}

/**
 *
 * @param data
 * @param options
 * @param expandFilters
 * @param facet
 * @returns {{options: *, facets, mobileDesktopClasses, moreLessFilters: string, expandFilters: *, view: {add, remove}}}
 */
export const sidebar = (data, options, expandFilters, facet) => {
    let facets = {};
    let isResults = data.response.resultPacket.results.length;
    if(isResults) {
        facets = sidebarFacets(filterEmptyCategories(data.response.facets), FOR_SEARCH_TOOLS, data.question.selectedFacets);

    }
    else {
        facets.remove = [];
        let names = {
            'f.Educational+level%7CB': 'Educational Level',
            'f.Subject%7Cs': 'Subject'
        }
        Object.keys(options).forEach(function facetFromOptions(item) {
            if(item.substr(0, 2) == 'f.' && item.substr(0, 5) != 'f.Tab') {
                facets.remove = facets.remove.concat(
                    {   name: typeof names[item] != 'undefined' ? names[item] : item,
                        catValues: [{label: capitalizeAll(decodePlus(options[item])), queryStringParam: `${item}=${options[item]}` }],
                        categories: [{}]
                    }

                )
            }
        });

        facets.select = [];
    }
    return {
        options,
        facets: facets,
        mobileDesktopClasses: mobileDesktopClasses(options),
        moreLessFilters: !expandFilters ? 'More' : 'Less',
        expandFilters,
        view: {
            add: facet.main('add', facet.cat.add, isMobileOnly),
            remove: isResults ? facet.main('remove', facet.cat.remove, isMobileOnly) : facet.main('remove', facet.cat.removeNoResults, isMobileOnly)
        }
    }
}