"use strict";
import { toolbarFacets } from '../viewutils';
import { FOR_SEARCH_TOOLS, TAB_FACET } from '../config'
import { capitalizeAll } from '../utils';

const noneFound = (currentQuery, options) =>
    `'${currentQuery == '!padrenull' ? options['pageTitle'] : currentQuery}' ${options[TAB_FACET]}`

/**
 * Model for views/content.noResultsContainer
 * @param currentQuery
 * @param resultPacket
 * @param facets
 * @param options
 * @param categoryCls
 * @returns {{options: *, facets, resultPacket: *, noneFound: string, view: {facet: *}}}
 */
export const noresults = (currentQuery, response, facets, options, categoryCls) => {
    return {
        options,
        facets: toolbarFacets(facets, FOR_SEARCH_TOOLS),
        resultPacket: response.resultPacket,
        exhibits: response.curator.exhibits,
        noneFound: capitalizeAll(noneFound(currentQuery, options)) ,
        view: {
            facet: categoryCls(options)
        }
    }
}