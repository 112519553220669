import {
    html
} from '../viewutils'

/**
 * View: Search form
 * @returns {*}
 */
export const searchForm = () => html`
        <form action="/search/" onsubmit="return ev(this, 'search')" class="site-search-form fb-autocomplete-form" role="search" aria-labelledby="search-heading" 
        novalidate="novalidate">
            <label id="search-heading" class="site-search__title" for="site-search_input">Search for products or information</label>
            <input type="search" name="query" placeholder="Search for products or information" size="30" id="site-search_input" class="site-search__text-input" 
            autocomplete="off" onkeyup="return ev({obj: this, event: event}, 'autosuggest')" onfocus="return ev(this, 'searchInputFocus')"  />
            <div id="autocomplete-results" class="hide"></div>
            <button type="submit" value="Submit" class="site-search__submit">
                <span class="icon icon-search" aria-hidden="true"></span>
                <span class="visuallyhidden">Submit</span>
            </button>
        </form>
    `;
