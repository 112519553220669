import {TAB_FACET, DEFAULT_FACETS} from '../config'
import {html} from '../viewutils'
import {capitalizeAll} from '../utils'

const facet = {};
facet.toolbarcat = {};
facet.cat = {};

/**
 * Used for select.options option
 * @param opt
 * @param selected
 */
const selectedMarkup = (opt, selected) =>
    opt.name == selected ? ' selected="selected"' : "";

/**
 * Used for select.options option ib nibuke
 * @param categoryItem
 * @param options
 */
const selectedMarkupMobile = (categoryItem, options) =>
    options[TAB_FACET].toLowerCase().replace(/\+/gi, " ") == categoryItem.label.toLowerCase() ? ' selected="selected"' : '';

/**
 * Used for mobile select.options option value
 * @param categoryItem
 * @param options
 */
const optionValue = (categoryItem, options) =>
    categoryItem.queryStringParam.replace(new RegExp(options[TAB_FACET] + '=', 'gi'), '');

/**
 * Category is currently selected
 * @param categoryItem
 * @param options
 */
const isCurrent = (categoryItem, options) =>
    options[TAB_FACET].toLowerCase().replace(/\+/gi, " ") == categoryItem.label.toLowerCase() ? ' current' : '';

/**
 * If more is not active for the facet then only first 5 categories should be shown for the facet
 * Display is controlled on a parent element
 * @param index
 */
const maybeShow = index =>
    index < 5 ? "show-category" : "hide-category";

/**
 * Show more categories on the facet
 * @param id
 */
facet.cat.more = (id) => `
    <a href="javascript:void(0);" onclick="return ev({main: this, id: '${id}'}, 'animateFacet')" 
        class="filters__more-button search-toggle-more-categories" title="Show more categories from this facet">MORE</a>
    `;

/**
 * View: for facet category
 * @param {CategoryValue} categoryItem
 */
facet.cat.add = (categoryItem, index) => `
        <li class="filters__item ${maybeShow(index)}">
            <a class="filters__link" href="javascript:void(0)" onclick="return ev(this, 'facet')" data-qstring="${categoryItem.queryStringParam}">${capitalizeAll(categoryItem.label)}</a>&nbsp; 
            <span class="filters__count">(<span class="categoryCount">${categoryItem.count}</span>)</span>
        </li>        
    `;


/**
 * View: for remove facet category
 * @param {CategoryValue} categoryItem
 */
facet.cat.remove  = (categoryItem, index) => `
        <div class="filters__selected-group">
            <h4 class="facetLabel selected">${capitalizeAll(categoryItem.label)}</h4>
            <a href="javascript:void(0);" onclick="return ev(this, 'remove')" data-remove="${categoryItem.queryStringParam}" 
                class="filters__more-button" title="Remove ${categoryItem.label} filter">
                <span class="icon icon-close"></span>
                <span class="visuallyhidden">Remove ${categoryItem.label} filter</span>
            </a>
        </div>       
    `;


/**
 * View: for remove facet category
 * @param {CategoryValue} categoryItem
 */
facet.cat.removeNoResults  = (categoryItem, index) => `
        <div class="filters__selected-group">
            <h4 class="facetLabel selected">${categoryItem.label}</h4>
            <a href="javascript:void(0);" onclick="return ev(this, 'remove')" data-remove="${categoryItem.queryStringParam}" 
                class="filters__more-button" title="Remove ${categoryItem.label} filter">
                <span class="icon icon-close"></span>
                <span class="visuallyhidden">Remove ${categoryItem.label} filter</span>
            </a>
        </div>       
    `;


/**
 * View: for facet category Fallback
 * @param {CategoryValue} categoryItem
 */
facet.cat.addF = qStringFacetAdd => categoryItem => {
    return `
        <li class="filters__item">
            <a class="filters__link" href="${qStringFacetAdd(categoryItem.queryStringParam)}">${categoryItem.label}</a>&nbsp; 
            <span class="filters__count">(<span class="categoryCount">${categoryItem.count}</span>)</span>
        </li>        
    `;
};

/**
 * View: for remove facet category for Fallback
 * @param {CategoryValue} categoryItem
 */
facet.cat.removeF = qStringFacetRemove => categoryItem => {
    return `
        <div class="filters__selected-group">
            <h4 class="facetLabel selected">${categoryItem.label}</h4>
            <a href="${qStringFacetRemove(categoryItem.queryStringParam)}" class="filters__more-button" title="Remove ${categoryItem.label} filter"><span class="icon icon-close"></span><span class="visuallyhidden">Remove ${categoryItem.label} filter</span></a>
        </div>           
    `;
};


/**
 * View: Basic option template for select
 * @param selected
 */
facet.toolbarcat.option = selected => opt =>
    `<option value="${opt.name}"${selectedMarkup(opt, selected)}>${opt.label.toUpperCase()}</option>`;

/**
 * View: for facet category
 * @param {CategoryValue} categoryItem
 */
facet.toolbarcat.mobile = options => (categoryItem, index) =>
    `<option value="${optionValue(categoryItem, options)}"${selectedMarkupMobile(categoryItem, options)}>${categoryItem.label.toUpperCase()}</option>`;

/**
 * View: for facet category
 * @param {CategoryValue} categoryItem
 */
facet.toolbarcat.default = options => (categoryItem, index) => categoryItem.count ? `
        <li class="filters__item ${index < 5 ? "show-category" : "hide-category"}">
            <a class="filters__link${isCurrent(categoryItem, options)}" href="javascript:void(0)" onclick="return ev(this, 'facet')" data-qstring="${categoryItem.queryStringParam}">${categoryItem.label}</a>&nbsp; 
            <span class="filters__count">(<span class="categoryCount">${categoryItem.count}</span>)</span>
        </li>        
    ` : "";

facet.toolbarcat.fallback = (options, qStringFacetRemoveAdd) => (categoryItem, index) => categoryItem.count ? `
        <li>
            <a class="results--type" href="${qStringFacetRemoveAdd(categoryItem.queryStringParam)}">
                ${categoryItem.label} ( ${categoryItem.count} )
            </a>
        </li>
    ` : `
        <li>
            <span class="grey">
                ${categoryItem.label} ( ${categoryItem.count} )
            </span>
        </li>
    `;

/**
 * View: for individual facet
 *
 * @param idPrefix add|remove
 * @param category
 * @param isMobileOnly
 */
facet.main = (idPrefix, category, isMobileOnly) => ({name, catValues}, index) => {
    let id = `${idPrefix}-facet-${index}`;
    return html`
        <div id="${id}" class="facet${DEFAULT_FACETS.indexOf(name.toLowerCase()) != -1  ? '' : ' facet-hidden'}">
            <section>
                <div class="filters__container">
                    <div class="filters__group-h">
                        <h4 class="facetLabel">${name == 'Educational level' ? 'Search Within' : name}</h4>
                        ${catValues.length > 5 ? facet.cat.more(id) : ""}
                    </div>
                    <ul class="filters__list">
                        ${catValues.map(category)}
                    </ul>
                </div>
            </section>
        </div>    
    `;
};

export {facet}