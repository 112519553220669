/**
 *
 *
 *
 *
 *  General Utils
 */

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
const isObject = (val) => val !== null && typeof val === 'object';


/**
 * Determine if a value is a Date
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Date, otherwise false
 */
const isDate = (val)  => toString.call(val) === '[object Date]';


/**
 * Reverse an object
 * @param json
 * @returns {{}}
 */
function swap(obj) {
    var ret = {};
    for (var key in obj) {
        ret[obj[key]] = key;
    }
    return ret;
}

const decode = str =>
    str.replace(/%20/gi, ' ')
        .replace(/\+/gi, ' ')
        .replace(/%26/gi, '&')
        .replace(/%26/gi, '&')
        .replace(/%2F/gi, '/')
        .replace(/%27/gi, "'");


const decodePlus = str =>
    decode(str).replace(/%7C/gi, '|')

/**
 * Capitalize word
 * @param qstring
 */
const capitalize = qstring =>
    qstring.charAt(0).toUpperCase() + qstring.slice(1);

/**
 * Capitalize all words
 * @param qstring
 */
const capitalizeAll = qstring =>
    decode(qstring).split(" ").map(capitalize).join(" ").replace(/Png|png/gi, 'PNG')

/**
 * Callback for when an element appears on the dom
 * @param document
 * @param id
 * @param fn
 * @returns {number}
 */
const ready = (document, id, fn) => {

    if (!document.getElementById(id)) {

        return setTimeout(() => ready(document, id, fn), 100);
    }
    else {
        fn(id);
    }
}

/**
 * @returns {string}
 */
function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

/**
 * Create a map using 'param' as the key from an array of objects
 * @param param
 */
const namedMap = param => objs => {
    let final = {};
    objs.forEach(obj => final[obj[param]] = obj)
    // for(let i in objs) {
    //     let obj = objs[i];
    //     final[obj[param]] = obj;
    // }
  
    return final
};

/**
 *
 * @param window
 */
const getWindowCls = window => () => window;

/**
 * Create new object
 * @param obj
 * @returns {{}}
 */
const newObj = (obj) => {
  let final = {};
  for (let k in obj) final[k] = obj[k];
  return final;
}

/**
 * Check if a key exists in the object and has the passed value
 * @param obj
 * @param key
 * @param value
 */
const check = (obj, key, value) =>
    typeof obj[key] != 'undefined' && obj[key] == value;

/**
 * Get value of object key and return replaced value
 * @param obj
 * @param key
 * @param find
 * @param replace
 * @returns {*}
 */
const objReplace  = (obj, key, find, replace) => {
    if(typeof obj[key] != 'undefined') {
        return obj[key].replace(new RegExp(find, 'gi'), replace)
    }
    else {
        return "";
    }
}


export {
    getWindowCls,
    isObject,
    isDate,
    swap,
    capitalize,
    capitalizeAll,
    slugify,
    namedMap,
    newObj,
    ready,
    check,
    decode,
    decodePlus,
    objReplace
};