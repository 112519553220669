"use strict";
import {ORDER} from '../config';
import {Breadcrumb} from '../types';
import {namedMap, capitalizeAll} from '../utils';
import {
    title,
    titleFromQVal,
    queryOnly,
    titleFromQuery,
    encode
} from '../queryutils';

/**
 * Get the breadcrumb title
 * @param options
 */
const breadcrumbQueryTitle = options =>
    options['query'] == '!padrenull' ?
        options['pageTitle'] :
        options['query'];

/**
 * Formatted breadcrumb title
 * @param str
 */
const cleanBreadcrumbTitle = str =>
    capitalizeAll(str.replace(/%20/gi, ' ').replace(/%7C/gi, '|'))

/**
 * Create breadcrumb from facet query string part
 * @param qstring
 */
const getBreadcrumb = qstring => new Breadcrumb(
    cleanBreadcrumbTitle(titleFromQVal(qstring)),
    cleanBreadcrumbTitle(title(qstring)),
    qstring
);

/**
 * Breadcrumb factory
 * @param options
 */
const getQueryFromBreadcrumb = options =>
    new Breadcrumb(
        titleFromQuery(breadcrumbQueryTitle(options)),
        'query', queryOnly(options)
    )

/**
 * Create a map using the title param
 */
const titleMap = namedMap('facetTitle');

/**
 * Remove encoded values from URI components
 * @param obj
 * @returns {{}}
 */
const cleanUriComponents = (obj) => {
    let final = {};
    for (let k in obj) final[capitalizeAll(k.replace(/%20/gi, ' '))] = obj[k];
    return final;
}


/**
 * Get an array of breadcrumbs in correct order from query string facets
 * @param order
 * @param facets
 * @returns {Array.<Breadcrumb>}
 */
const getBreadcrumbs = (options, order, facets) => {
    let byFacetTitle = cleanUriComponents(titleMap(facets.map(getBreadcrumb)));
    let items = [getQueryFromBreadcrumb(options)].concat(
        order.filter(
            item => typeof byFacetTitle[item] != 'undefined'
        ).map(item => byFacetTitle[item])
    ).filter(item => item.qstring != '?collection=oup-meta&query=!padrenull');

    return items;
};

/**
 * Get the facet strings of selectedCategoryValues
 * @param selectedCategoryValues
 */
const facetStrings = selectedCategoryValues =>
    Object.keys(selectedCategoryValues).map(item => `${encode(item)}=${ selectedCategoryValues[item][0] }`)

/**
 * Determine whether breadcrumbs should be shown or not.
 * @param options
 * @returns {*}
 */
const showBreadcrumbs = options => {
        if((typeof options['filters'] != 'undefined' && options['filters'] == 'open') ||
            typeof options['device'] == 'undefined' ||
            options['device'] == 'is-mobile-only'
        ) {
            return 'visuallyhidden';
        }
        else {
            return 'show-breadcrumbs'
        }
}

/**
 * Breadcrumb model for views/breadcrumbs
 * @param selectedCategoryValues
 * @param options
 * @returns {{breadcrumbs: Array.<*>, showBreadcrumbs: *, options: *}}
 */
export const breadcrumbs = (selectedCategoryValues, options) => {
    return  {
        breadcrumbs: [].concat(getBreadcrumbs(options, ORDER, facetStrings(selectedCategoryValues))),
        showBreadcrumbs: showBreadcrumbs(options),
        options
    }
}