import {html} from '../viewutils';

/**
 * View which includes the element dependencies for this app.  breadcrumbs|sidebar|content-v2
 * These may or may not be available on the page.  If they are not available and search is activated
 * these are injected.
 */
const main = () => html`
        <!-- BREADCRUMBS -->
        <section class="smallGap">
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12" id="breadcrumbs">
               
                </div>
            </div>
        </section>
        <!-- /BREADCRUMBS -->
        <section>
            <div class="row">
                <!-- SIDEBAR -->
                <div class="col-xs-12 col-md-4 col-lg-3" id="sidebar">
              
                </div>
                <!-- /SIDEBAR -->
                <!-- SEARCH ELS.content -->
                <div class="col-xs-12 col-md-8 col-lg-9" id="content-v2">
              
                </div>
                <!-- /SEARCH ELS.content -->
            </div>
        </section>
    `;

export default main