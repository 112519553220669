import {html, viewSelected, highlightWords,cleanCategories, getSortOptions, METAMAP, getMetadata} from '../viewutils'
import {getKey, joinQuery, titleFromQuery, clean} from '../queryutils'
import {FOR_SEARCH_TOOLS, CARTID, SORT_OPTIONS, TAB_FACET, DEFAULT_FACETS, DOMAIN, COMPUTED} from '../config'
import {objReplace, ready, decodePlus, capitalizeAll} from '../utils';

const content = {};
const show = {};
show.mobile = {};
show.tablet = {};


const isPagination = resultsSummary =>
    resultsSummary.nextStart;

const isPadreNull= options =>
    options.query == '!padrenull'

/**
 * Get the first facet key
 * @param options
 */
const firstFacetKey = options => {
    //Object.keys(options).find(key => key.substr(0, 2) == 'f.')
    let keys = Object.keys(options);
    let i = 0;
    let firstFacetKey = '';
    while (i < keys.length) {
        if(keys[i].substr(0, 2) == 'f.') {
            firstFacetKey = keys[i];
            break;
        }
        i++;
    }
    return firstFacetKey;
}


/**
 * Get the first facet value
 * @param options
 * @param key
 */
const firstFacetValue = (options, key) =>
    key ? options[key] : null

/**
 * Changes content.summary values depending on query
 * @param isPadreNull
 * @param resultsSummary
 * @param resultPacket
 */
const maybeQuery = (isPadreNull, resultsSummary, resultPacket) =>
    isPadreNull ? content.summary(resultsSummary.totalMatching , "All Results") : content.summary(resultsSummary.totalMatching , resultPacket.queryCleaned)

/**
 * Changes content.summary values depending on query and category
 * Factors include isPadreNull, isPadreNull and Category, has pageTitle in the query string, or is a proper query
 * @param isPadreNull
 * @param category
 * @param resultsSummary
 * @param resultPacket
 * @param options
 */
const maybeResultsSummary = (isPadreNull, category, resultsSummary, resultPacket, options) =>
    isPadreNull && category ?
        content.summary(resultsSummary.totalMatching, options.pageTitle ? capitalizeAll(options.pageTitle) : titleFromQuery(category)) :
        maybeQuery(isPadreNull, resultsSummary, resultPacket)

const result = {};


/**
 * gtmData

 * adds data attributes for GTM tracking for the addToCart function to send to the dataLayer
 * requires the class gtm-product-data to be present on the element and that the element be a parent of the Add To Cart button
 * data - funnelback metadata (uses original funnelback metaData names)
 * @param data
 */

const gtmData = (data) => {

    return `
    data-gtm-name="${data.t}"
    data-gtm-id="${data.b}"
    data-gtm-price="${data['3']}"
    data-gtm-category="${data.s}"
    data-gtm-variant="${data.E}"
    data-gtm-book-id="${data['1']}"
    data-gtm-quantity="1"
    data-gtm-stock-at-hand=""
    `
};

/**
 * View: For add to cart button
 * @param cartFlag
 * @param assetid
 */

content.addToCartButton = (cartFlag, assetid, data) => {
    if (cartFlag === 'Y') {
        return `
            <a href="./?a=${CARTID}&buy[${clean(assetid)}]=1&SQ_DESIGN_NAME=blank" class="button primary w100 mrgTop0 mrgBtm0 addToCart add-to-cart add-to-cart-search"

            ><span class="label">Add to cart</span></a>
        `;
    } else {
        return `
            <a href="javascript:void(0);" tabIndex="-1" aria-disabled="true" class="product--cart__disabled button primary w100 mrgTop0 mrgBtm0 addToCart add-to-cart"><span class="label">Add to cart</span></a>
        `;
    }
};

var __isNz = (cookieKey, cookieValue) => () =>
    document.cookie
            .split('; ')
            .reduce((ac, it) => {
                const kv = it.split('=');

                if(kv[0].trim() === cookieKey) {

                    ac = (kv[1].trim() === cookieValue) || ac;
                }
                console.log(kv);
                return ac;
                }, false);

var isNZ = __isNz('currency', 'NZD');

const price = (resultItem, METAMAP) =>
    isNZ() ? `$${getMetadata(resultItem, METAMAP.price_nz)} <em>NZD</em></p>`  :`$${getMetadata(resultItem, METAMAP.price_au)} <em>AU</em>`;

/**
 * View: For search list result
 * @param {Result} resultItem
 */
result.product = (query, listType) => (resultItem) => {

    let edition = getMetadata(resultItem, METAMAP.edition);
    edition = edition ? edition + ", " : "";
    let image = getMetadata(resultItem, METAMAP.image);
    let guessed_image = '/new_book_images/covers/' + getMetadata(resultItem, METAMAP.isbn) + '.jpg';
    image = !!image ? image : guessed_image;
    image = image.match(/product-placehoder_large/i) ? guessed_image : image;
    let salesStatus = getMetadata(resultItem, METAMAP.prodSalesStatus);
    var rawAudPrice = getMetadata(resultItem, METAMAP.price_au); //(0, _viewutils.getMetadata)(resultItem, _viewutils.METAMAP.price_au);
    var priceTooLow = (parseFloat(rawAudPrice,10) < 0.1);
    var priceString = price(resultItem, METAMAP); //price(resultItem, _viewutils.METAMAP);
    //var displayPrice = salesStatus !== 'NS' ? '<p class="product--tile__price">' + price(resultItem, _viewutils.METAMAP) + '</p>' : '';
    var displayPrice = salesStatus !== 'NS' && !priceTooLow ? priceString : '';

    // let displayPrice = salesStatus !== 'NS'
    //     ? `<p class="product--tile__price">${price(resultItem, METAMAP)}</p>`
    //     : '';
    let cartButton = salesStatus !== 'NS' && !priceTooLow
        ? content.addToCartButton(getMetadata(resultItem, METAMAP.cart_flag), getMetadata(resultItem, METAMAP.assetid), resultItem.metaData)
        : '';
    return `
    <div class="${listType}">
        <div class="panel">
            <div class="tile product--tile">
                <div class="padder">
                    <a href="${DOMAIN}${resultItem.clickTrackingUrl}" class="product--tile__image" style="background-image: url('${image}');" title="${resultItem.title}"></a>
                    <div class="product--tile__details gtm-product-data ${priceTooLow ? 'no-cart-button' : ''}"
                    ${gtmData(resultItem.metaData)} >
                        <p class="product--tile__details__title">${edition}${getMetadata(resultItem, METAMAP.year_published)}</p>
                        <p class="product--tile__title"><a href="${DOMAIN}${resultItem.clickTrackingUrl}">${highlightWords(query, resultItem.title)}</a></p>
                        <p class="product--tile__category">${getMetadata(resultItem, METAMAP.format)}</a>
                        ${displayPrice}
                        ${cartButton}
                    </div>
                </div>
            </div>
        </div>
    </div>
    `;
};

/**
 * View: For search list result
 * @param {Result} resultItem
 */
result.events = (query, location) => (resultItem) => {

    let date_string = getMetadata(resultItem, METAMAP.date_published).substr(0, 19);
    let d = new Date(date_string);
    return `
    <div class="col-xs-12 col-md-12 col-lg-12">
        <div class="panel">
            <div class="tile event--tile">
                <div class="padder">
                    <div class="event--tile__date">
                        <span class="icon icon-calendar-o"></span>
                        <time datetime="${date_string.substr(0,10)}">
                            <p class="event--tile__date-day">${date_string.substr(8,2)}</p>
                            <p class="event--tile__date-month">${d.toDateString().substr(4, 3)}</p>
                        </time>
                    </div>
                    <div class="event--tile__description">
                        <p class="event--tile__description-category">${getMetadata(resultItem, METAMAP.subject)}</p>
                        <a class="event--tile__description-title" href="${DOMAIN}${resultItem.clickTrackingUrl}">${highlightWords(query, resultItem.title)}</a>
                        <p class="event--tile__description-summary">${highlightWords(query, resultItem.summary)}</p>
                        <div class="event--tile__details">
                            <p class="label">Location</p>
                            <p class="value">${resultItem.metaData.eventState}</p>
                            <p class="label">Cost</p>
                            <p class="value">$${resultItem.metaData.eventCost}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `;
};

/**
 * View: For search list result
 * @param {Result} resultItem
 */
result.contents = (query, location) => (resultItem) => {
    return `
    <div class="col-xs-12 col-md-12 col-lg-12">
        <div class="panel">
            <div class="tile content--tile">
                <div class="padder">
                    <div class="content--tile__description">
                        <a class="content--tile__description-title" href="${DOMAIN}${resultItem.clickTrackingUrl}">${highlightWords(query, resultItem.title)}</a>
                        <p class="content--tile__description-summary">${highlightWords(query, resultItem.summary)}</p>
                        <a class="content--tile__description-url" href="${DOMAIN}${resultItem.clickTrackingUrl}">${resultItem.displayUrl.replace(location.origin, '')}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `;
};

content.result = result;

/**
 * View for bestBet
 * @param {BestBet} bestBetItem
 */
content.bestBet = (bestBetItem) => {
    return `
        ${bestBetItem.descriptionHtml}
    `;
};

/**
 * View for bestBets
 * @param {Array.<BestBet>} bestBetItems
 */
content.bestBets = (bestBetItems) =>
    bestBetItems.length ? html`
        <div class="panel" id="best-bets-content">
            <div class="carousel-holder landing">
                <div class="slick-slideshow">
                    ${bestBetItems.map(content.bestBet)}
                    <div class="slick-slideshow__paging">
                        <ul class="slick-actions"></ul>
                    </div>
                </div>
            </div>
        </div>
    ` : "";

/**
 * Summary of results
 * @param count
 * @param query
 */
content.summary = (count, query) => `
        <p class="results--count">${count} Results for</p>
        <p class="results--query">${clean(capitalizeAll(query.replace(new RegExp(COMPUTED, 'gi'), '')))}</p>
    `

/**
 * Mobile filters button used to toggle filters
 * @param options
 * @param filters
 */
show.mobile.filtersButton = (options, filters) =>
    options.device == 'is-mobile-only' ? `
        <a href="javascript:void(0);" onclick="return ev(this, 'facet')" data-qstring="${filters}"
            class="button mrgTop0 tablet-hide pull-right show-filters" role="button">
            <span class="label">Filters</span>
        </a>
    ` : "";

/**
 *
 * @param options
 * @param facets
 * @param view {{facet.toolbarcat.default}}
 */
show.tablet.toolbarFacets = (device, facets, view) =>
    device != 'is-mobile-only' ? html`
        <ul class="results--types mobile-hide tablet-show">
            ${facets.map(view)}
        </ul>
    ` : "";

/**
 * Pagination button displayed after list items
 * @param resultsSummary
 * @param options
 */
content.pagination = (resultsSummary, options) => html`
    <div class="col-xs-12 col-md-12 col-lg-12">
        <a href="javascript:void(0)" onclick="return ev(this, 'pagination')" data-next="${resultsSummary.nextStart}" class="button primary w100 mrgTop0 mrgBtm40"><span
        class="label" id="pagination-text">View More ${options[TAB_FACET].replace(/\+/gi, ' ')}</span></a>
    </div>
`;

/**
 * View: Basic option template for select
 * @param selected
 */
content.optionBasic = (selected) => (opt) => {
    let selectedMarkup = opt.name == selected ? ' selected="selected"' : "";
    return `<option class="whoa" value="${opt.name}"${selectedMarkup}>${opt.label.toUpperCase()}</option>`;
}

content.toolbar = model => html`
    <div class="row bottomBorder">
        <div class="col-xs col-md-12 col-lg-12">
            ${ maybeResultsSummary(
                isPadreNull(model.options),
                firstFacetValue(model.options, firstFacetKey(model.options)),
                model.resultsSummary,
                model.resultPacket,
                model.options
            )}
            ${show.tablet.toolbarFacets(model.options.device, model.facets, model.view.facet)}
        </div>
        <div class="col-xs col-md col-lg">
            ${show.mobile.filtersButton(model.options, model.filters)}
        </div>
    </div>
    <div class="row withPanels">
        <div class="col-xs col-md col-lg">
            <div class="icon--tools mobile-hide tablet-show pull-right">
                <a href="javascript:void(0);"
                    data-view="grid" onclick="return ev(this, 'listType')" class="icon icon-th-large ${viewSelected(model.options, 'grid')}">
                    <span class="visuallyhidden">List View</span>
                </a>
                <a href="javascript:void(0);"
                    data-view="list" onclick="return ev(this, 'listType')" class="icon icon-list ${viewSelected(model.options, 'list')}">
                    <span class="visuallyhidden">Grid View</span>
                </a>
            </div>
            <div class="col-xs-12">
                <div id="typesSelect">
                <label for="searchType" class="visuallyhidden">Search Type</label>
                <select onchange="return ev(this, 'selectFacet')" class="tablet-hide types__select styled" id="searchType" title="Search Type">
                    ${model.facets.map(model.view.facet)}
                </select>
                </div>
            </div>
            <div class="col-xs-12">
                <label class="visuallyhidden" for="sort_type">Sort By</label>
                <select onchange="ev(this, 'sort')" class="styled types__select" tilte="Sort By" id="sort_type">
                    ${model.sortOptions.map(model.view.currentSort)}
                </select>
            </div>
        </div>
    </div>
`;

/**
 * Main content view using models/content
 * @param model
 */
content.main = model => html`
    <a name="content-v2"></a>
    ${content.bestBets(model.exhibits)}
    <div class="panel search-tools-${model.tabFacet}">
        <div class="padder">
        ${model.view.toolbar(model)}
        </div>
    </div>
    <div class="row" id="resultset">
        ${model.results.map(model.view.result)}
        <!-- NextResults -->
        ${isPagination(model.resultsSummary) ? model.view.pagination(model.resultsSummary, model.options) : ""}
    </div>
    <div id="result-set-${model.options[TAB_FACET]}"></div>
`;

// for desktop? <style>.filters__more-button, .more--facets { display: block;  }</style>
/**
 * Show no results found with didYouMean if value
 * @param didYouMean
 */
content.noResults = didYouMean => `
    <p class="results--count error">0 results for</p>
    <p class="results--query">${didYouMean.replace(new RegExp(COMPUTED, 'gi'), '')}</p>
    <div class="content-viewport">
        <p>Here are some search hints to help you find what you are looking for:</p>
        <ul>
            <li>Check your spelling for author name/book title/keyword/ISBN</li>
            <li>Try using different keywords</li>
            <li>Adjust your filters</li>
            <li>Use general search terms</li>
        </ul>
    </div>`;

/**
 * Used if there is a spelling suggestion from funnelback.
 * @param text
 */
content.didYouMean = text =>
    `<i><a href="javascript:void(0);" onclick="return ev('${text}', 'newQuery')">Did you mean '${text}'?</a></i>`;

/**
 * Content view for no results.
 * @param model models/noresults
 */
content.noResultsContainer = model => `
    <a name="content-v2"></a>
    ${content.bestBets(model.exhibits)}
    <div class="panel">
        <div class="padder">
            <div class="row">
                <div class="col-xs col-md-12 col-lg-12">
                    ${content.noResults(
                        model.resultPacket.spell ? content.didYouMean(clean(capitalizeAll(model.resultPacket.spell.text))) : clean(capitalizeAll(model.noneFound)))
                    }
                </div>
            </div>
        </div>
    </div>
    <div class="panel">
        <div class="padder">
            <h3>Need more help?</h3>
            <p>Please contact our Customer Service department on <strong><a class="tel" href="tel:1300 650 616">1300 650 616</a></strong> or email at <a href="mailto:cs.au@oup.com">cs.au@oup.com</a>
        </div>
    </div>`;


content.loading = () => `
    <section>
        <div class="row">
            <div class="col-xs-12 col-md-12 col-lg-12"> <!-- content-viewport -->
                <div class="panel maintenance">
                    <div class="padder">
                        <div class="row center-xs content-viewport ">
                            <div class="col-lg-4">
                                <div class="icons">
                                    <span class="icon icon-gear icon-spin right slow one"></span>
                                    <span class="icon icon-gear icon-spin left two"></span>
                                    <span class="icon icon-gear icon-spin left three"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
`;

const fallback = {};

/**
 * Input option for fallback sort
 * @param accum
 * @param key
 * @param index
 * @param arr
 * @returns {string|*}
 */
const inputOption = (accum, key, index, arr) => {
    let value = accum.options[key];
    let ignore = ['sort'];
    if(ignore.indexOf(key) == -1) {
        accum.result = accum.result + `<input type="hidden" name="${decodePlus(key)}" value="${value}" />`
    }
    // If this is the final array item return the string result else return the object
    let r = (arr.length - 1) == index ? accum.result : accum;
    return r;
}


/**
 * Custom pagination for fallback version
 * @param qStringFacetRemoveAdd
 */
fallback.pagination = qStringFacetRemoveAdd => (resultsSummary, options) =>
    html`
        <div class="col-xs-12 col-md-12 col-lg-12">
            <a href="${qStringFacetRemoveAdd('start_rank=' + resultsSummary.nextStart)}" class="button primary w100 mrgTop0 mrgBtm40"><span
            class="label">View More ${objReplace(options, 'f.Tabs|type=', "+", " ")}</span></a>
        </div>
    `;

fallback.toolbar = (location, qStringFacetAdd) => model => html`
    <div class="row bottomBorder">
        <div class="col-xs col-md-12 col-lg-12">
            ${ maybeResultsSummary(
                isPadreNull(model.options),
                firstFacetValue(model.options, firstFacetKey(model.options)),
                model.resultsSummary,
                model.resultPacket,
                model.options
            )}
            ${show.tablet.toolbarFacets(model.options.device, model.facets, model.view.facet)}
        </div>
        <div class="col-xs col-md col-lg">
            ${show.mobile.filtersButton(model.options, model.filters)}
        </div>
    </div>
    <form action="${location.pathname}" method="get">
    ${Object.keys(model.options).reduce(inputOption, {options: model.options, result:""})}
        <div class="row withPanels">

            <div class="col-xs col-md col-lg">
                <div class="icon--tools mobile-hide tablet-show pull-right">
                    <a href="${qStringFacetAdd('listType=grid')}"
                        class="icon icon-th-large ${viewSelected(model.options, 'grid')}">
                        <span class="visuallyhidden">List View</span>
                    </a>
                    <a href="${qStringFacetAdd('listType=list')}"
                         class="icon icon-list ${viewSelected(model.options, 'list')}">
                        <span class="visuallyhidden">Grid View</span>
                    </a>
                </div>
                <div class="col-xs">
                    <div id="typesSelect"></div>
                </div>
                <div class="col-xs-12">
                    <label class="visuallyhidden" for="sort_type">Sort By</label>
                    <select name="sort" class="styled types__select" tilte="Sort By" id="sort_type">
                        ${model.sortOptions.map(model.view.currentSort)}
                    </select>
                    <input type="submit" class="button primary mrgTop0 pull-right" style="margin-right:1.25rem;" value="Update Filters" />
                </div>
            </div>
        </div>
    </form>
`;


export {content, fallback};
